import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRiskManagmentClose } from '../interfaces';

const initialState: IRiskManagmentClose = {
  segment: {
    value: 'preset',
    preset: {
      takeProfit: {
        isOpened: true,
        orders: 1,
        priceChange: 5,
        orderSizeScale: 1,
        orderPriceChangeScale: 1,
        orderType: 'market',
      },
      stopLoss: {
        isOpened: true,
        priceChange: -5,
        orderType: 'market',
      },
    },
    alert: {
      orderType: 'market',
    },
  },
};

export const riskManagmentCloseSlice = createSlice({
  name: 'risk-managment-close',
  initialState,
  reducers: {
    init: (state, action: PayloadAction<IRiskManagmentClose>) => {
      const {
        segment,
      } = action.payload;

      const {
        value,
        preset,
        alert,
      } = segment;

      state.segment = segment;
      state.segment.value = value;
      state.segment.preset = preset;
      state.segment.preset.takeProfit = preset.takeProfit;
      state.segment.preset.stopLoss = preset.stopLoss;
      state.segment.alert = alert;
    },
    toggleTakeProfit: (state, action: PayloadAction<IRiskManagmentClose['segment']['preset']['takeProfit']['isOpened']>) => {
      state.segment.preset.takeProfit.isOpened = action.payload;
    },
    toggleStopLoss: (state, action: PayloadAction<IRiskManagmentClose['segment']['preset']['stopLoss']['isOpened']>) => {
      state.segment.preset.stopLoss.isOpened = action.payload;
    },
    setError: (state, action: PayloadAction<IRiskManagmentClose['error']>) => {
      state.error = action.payload;
    },
    changeSegment: (state, action: PayloadAction<IRiskManagmentClose['segment']['value']>) => {
      state.segment.value = action.payload;
    },
    setTakeProfitOrderType: (state, action: PayloadAction<IRiskManagmentClose['segment']['preset']['takeProfit']['orderType']>) => {
      state.segment.preset.takeProfit.orderType = action.payload;
    },
    setTakeProfitOrders: (state, action: PayloadAction<IRiskManagmentClose['segment']['preset']['takeProfit']['orders']>) => {
      state.segment.preset.takeProfit.orders = action.payload;
    },
    setTakeProfitPriceChange: (state, action: PayloadAction<IRiskManagmentClose['segment']['preset']['takeProfit']['priceChange']>) => {
      state.segment.preset.takeProfit.priceChange = action.payload;
    },
    setTakeProfitOrdersSizeScale: (state, action: PayloadAction<IRiskManagmentClose['segment']['preset']['takeProfit']['orderSizeScale']>) => {
      state.segment.preset.takeProfit.orderSizeScale = action.payload;
    },
    setTakeProfitOrdersPriceChangeScale: (state, action: PayloadAction<IRiskManagmentClose['segment']['preset']['takeProfit']['orderPriceChangeScale']>) => {
      state.segment.preset.takeProfit.orderPriceChangeScale = action.payload;
    },
    setStopLossPriceChange: (state, action: PayloadAction<IRiskManagmentClose['segment']['preset']['stopLoss']['priceChange']>) => {
      state.segment.preset.stopLoss.priceChange = action.payload;
    },
    setStopLossOrderType: (state, action: PayloadAction<IRiskManagmentClose['segment']['preset']['stopLoss']['orderType']>) => {
      state.segment.preset.stopLoss.orderType = action.payload;
    },
    setAlertOrderType: (state, action: PayloadAction<IRiskManagmentClose['segment']['alert']['orderType']>) => {
      state.segment.alert.orderType = action.payload;
    },
  },
});

export const {
  actions: riskManagmentCloseActions,
} = riskManagmentCloseSlice;
export const {
  reducer: riskManagmentCloseReducer,
} = riskManagmentCloseSlice;
