import { Stack } from '@mui/material';
import { Copy, Label } from 'widgets';
import { generateCustomAlertSendRequest } from 'shared/helpers';
import { ITabProps } from '../../../interfaces';
import { WRAPPER } from '../styles';

export const Custom = (props: ITabProps) => {
  return (
    <Stack sx={WRAPPER}>
      <Copy
        label={(
          <Label
            title='JSON body to execute base entry'
            desciption='Configure a POST request with JSON body to execute base entry'
          />
        )}
        value={generateCustomAlertSendRequest(props.sourceUuid, props.secretToken, props.position)}
        display='row'
      />

      <Copy
        label={(
          <Label
            title='Webhook URL'
            desciption='Send a POST request with JSON on this adress'
          />
        )}
        value='https://panel.skyrexio.com/api/v1/deal/alert'
      />
    </Stack>
  );
};
