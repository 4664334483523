import { Box, Stack } from '@mui/material';
import { Text } from 'shared/ui';
import { IBlockProps } from '../interfaces';
import { TITLE_WRAPPER, WRAPPER } from '../styles';

export const Block = (props: IBlockProps) => {
  const {
    title,
    description,
    children,
    maxWidth,
  } = props;

  const getDescription = () => {
    if (!description) {
      return null;
    }

    return (
      <Text>
        {description}
      </Text>
    );
  };

  return (
    <Stack
      sx={{
        ...WRAPPER,
        maxWidth:{
          xs: '100%',
          md: maxWidth || 823,
        },
      }}
    >
      <Stack sx={TITLE_WRAPPER}>
        {title}
        
        {getDescription()}
      </Stack>

      {children && (
        <Box>
          {children}
        </Box>
      )}
    </Stack>
  );
};
