export const getCapitalizedExchangeTitle = (exchange: string) => {
  if (typeof exchange !== 'string' || exchange.length === 0) {
    return '';
  }
    
  if (['okx', 'htx'].includes(exchange)) {
    return exchange.toUpperCase();
  }

  if (exchange === 'crypto-com') {
    return (exchange.charAt(0).toUpperCase() + exchange.slice(1)).replace('-', '.');
  }

  if (exchange === 'gateio') {
    return 'Gate.io';
  }
    
  return exchange.charAt(0).toUpperCase() + exchange.slice(1);
};
