import { IRiskManagmentAuto } from 'entities/risk-managment-auto/interfaces';
import { BotData } from 'pages/trading-bots/my-bot/ui/my-bot';

export const getAutoValue = (bot: BotData) => {
  console.log('bot.settingsType', bot.settingsType ? 'auto' : 'custom');
  const value: IRiskManagmentAuto = {
    segment: bot.settingsType ? 'auto' : 'custom',
    preset: bot.settingsType || 'conservative',
    values: {
      additionalOrderPriceChange: 0,
      additionalOrderPriceChangeScale: 0,
      additionalOrderSizeScale: 0,
      maxAdditionalOrders: 0,
      stopLossPercent: 0,
      taketProfitPercent: 0,
    },
  };

  return value;
};
