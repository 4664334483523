import { Box, Stack } from '@mui/material';
import { getCurrentBot } from 'entities/bot/model/selectors/get-current-bot/get-current-bot';
import { getPreset, riskManagmentAutoActions } from 'entities/risk-managment-auto';
import { IRiskManagmentAuto } from 'entities/risk-managment-auto/interfaces';
import { presets } from 'pages/trading-bots/configurator-v2/consts';
import { getAutoValue } from 'pages/trading-bots/configurator-v2/helpers';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Panel, Preset } from '../../../components';
import { PRESETS, WRAPPER } from '../styles';

export const Auto = () => {
  console.log('3', 3);
  const dispatch = useDispatch();

  const bot = useSelector(getCurrentBot);
  const selectedPreset = useSelector(getPreset);

  const selectPresetHandler = useCallback((value: string) => {
    return () => {
      dispatch(riskManagmentAutoActions.selectPreset(value as IRiskManagmentAuto['preset']));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!bot?.botType) {
      return;
    }

    dispatch(riskManagmentAutoActions.init(getAutoValue(bot)));
  }, [bot?.botUuid, dispatch]);

  return (
    <Stack sx={WRAPPER}>
      <Box sx={PRESETS}>
        {presets.map((preset) => (
          <Preset
            key={preset.title}
            title={preset.title}
            settings={preset.settings}
            caption={preset.caption}
            description={preset.description}
            isSelected={selectedPreset === preset.title.toLowerCase()}
            onClick={selectPresetHandler(preset.title.toLowerCase())}
          />
        ))}
      </Box>

      <Panel type='common' />
    </Stack>
  );
};
