interface IGetPriceChange {
  position: number;
  segment: string;
  orders: number;
  ordersPriceChangeScale: number;
  priceChange: number;
}
export const getPriceChange = (params: IGetPriceChange) => {
  const {
    position,
    segment,
    orders,
    ordersPriceChangeScale,
    priceChange,
  } = params;

  if (segment === 'alert') {
    return null;
  }

  const orderPriceChangeScale = orders === 1 ? 1 : ordersPriceChangeScale;
  if (orderPriceChangeScale === 1) {
    return ((priceChange || -1) * position).toFixed(2);
  }

  return (priceChange * ((1 - (orderPriceChangeScale ** position)) / (1 - orderPriceChangeScale))).toFixed(2);
}; 
