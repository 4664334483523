import { Box, Stack } from '@mui/material';
import { getPresetOrders, getPresetOrdersPriceChangeScale, getPresetOrdersSizeScale, getPresetOrderType, getPresetPriceChange, riskManagmentAdditionalActions } from 'entities/risk-managment-additional';
import { IRiskManagmentAdditional } from 'entities/risk-managment-additional/interfaces';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { Label } from 'widgets';
import { CustomSlider, OrderType } from '../../../../../components';
import { INNER, WRAPPER } from '../styles';

export const Preset = () => {
  const dispatch = useDispatch();

  const orders = useSelector(getPresetOrders);
  const ordersSizeScale = useSelector(getPresetOrdersSizeScale);
  const priceChange = useSelector(getPresetPriceChange);
  const ordersPriceChangeScale = useSelector(getPresetOrdersPriceChangeScale);
  const orderType = useSelector(getPresetOrderType);

  const [ordersValue, setOrdersValue] = useState<number>(orders);
  const [ordersSizeScaleValue, setOrdersSizeScaleValue] = useState<number>(ordersSizeScale);
  const [priceChangeValue, setPriceChangeValue] = useState<number>(priceChange);
  const [ordersPriceChangeScaleValue, setOrdersPriceChangeScaleValue] = useState<number>(ordersPriceChangeScale);
  
  const debouncedValue = useDebouncedCallback((action: any, value: number | string) => {
    dispatch(action(value));
  }, 300);

  const changePresetOrdersHandler = (value: number) => {
    setOrdersValue(value);
    debouncedValue(riskManagmentAdditionalActions.setPresetOrders, value);
  };

  const changePresetOrdersSizeScaleHandler = (value: number) => {
    setOrdersSizeScaleValue(value);
    debouncedValue(riskManagmentAdditionalActions.setPresetOrdersSizeScale, value);
  };

  const changePresetPriceChangeHandler = (value: number) => {
    setPriceChangeValue(value);
    debouncedValue(riskManagmentAdditionalActions.setPresetPriceChange, value);
  };

  const changePresetOrdersPriceChangeScaleHandler = (value: number) => {
    setOrdersPriceChangeScaleValue(value);
    debouncedValue(riskManagmentAdditionalActions.setPresetOrdersPriceChangeScale, value);
  };

  const changePresetOrderTypeHandler = (value: string) => {
    dispatch(riskManagmentAdditionalActions.setPresetOrderType(value as IRiskManagmentAdditional['segment']['preset']['orderType']));
  };

  const isOneOrder = orders === 1;

  return (
    <Stack sx={INNER}>
      <Box sx={WRAPPER}>
        <CustomSlider
          title={(
            <Label
              title='Orders'
            />
          )}
          slider={{
            value: ordersValue,
            max: 50,
            marks: {
              1: 1,
              10: 10,
              50: 50,
            },
            onChange: changePresetOrdersHandler,
          }}
          input={{
            value: ordersValue,
            placeholder: '100%',
            onChange: changePresetOrdersHandler,
          }}
        />

        <CustomSlider
          title={(
            <Label
              title='Orders size scale'
            />
          )}
          slider={{
            value: ordersSizeScaleValue,
            max: 5,
            marks: {
              1: 1,
              5: 5,
            },
            onChange: changePresetOrdersSizeScaleHandler,
          }}
          input={{
            value: ordersSizeScaleValue,
            placeholder: '100%',
            onChange: changePresetOrdersSizeScaleHandler,
          }}
          disabled={isOneOrder}
          step={0.1}
        />

        <CustomSlider
          title={(
            <Label
              title='Price change, %'
            />
          )}
          slider={{
            value: priceChangeValue,
            max: 50,
            marks: {
              1: 1,
              10: 10,
              50: 50,
            },
            reversed: true,
            onChange: changePresetPriceChangeHandler,
          }}
          input={{
            value: priceChangeValue,
            placeholder: '100%',
            onChange: changePresetPriceChangeHandler,
          }}
          step={0.1}
        />

        <CustomSlider
          title={(
            <Label
              title='Orders price change scale'
            />
          )}
          slider={{
            value: ordersPriceChangeScaleValue,
            max: 50,
            marks: {
              1: 1,
              10: 10,
              50: 50,
            },
            onChange: changePresetOrdersPriceChangeScaleHandler,
          }}
          input={{
            value: ordersPriceChangeScaleValue,
            placeholder: '100%',
            onChange: changePresetOrdersPriceChangeScaleHandler,
          }}
          disabled={isOneOrder}
          step={0.1}
        />
        
        <OrderType
          value={orderType}
          onChange={changePresetOrderTypeHandler}
        />
      </Box>
    </Stack>
  );
};
