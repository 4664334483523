import { SxProps, Theme } from '@mui/material';

export const INNER: SxProps<Theme> = {
  flexDirection: 'row',
  gap: '30px',
  // justifyContent: {
  //   xs: 'center',
  //   md: 'space-between',
  // },
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: 'wrap',
};
