import { CurrentSymbol } from 'entities/terminal/model/types/terminal-schema';
import { currencyFormatter } from 'shared/helpers/currency-formatter';
import { ActiveDeal } from './fetch-all-deals';

export const getFormattedTotal = (exchangeSymbols: CurrentSymbol[], trade: ActiveDeal): string => {
  const symbol = `${trade.baseSymbol}${trade.quoteSymbol}`;
  const precision = exchangeSymbols.find((element) => element.symbol === symbol);

  return currencyFormatter(+trade.executedQuoteFirst, precision?.chartPrecision || 2);
};
  
export const getTotal = (trade: ActiveDeal, exchangeCode: string, binanceSymbols: CurrentSymbol[], bybitSymbols: CurrentSymbol[], okxSymbols: CurrentSymbol[]): string => {
  switch (exchangeCode) {
  case 'binance':
    return getFormattedTotal(binanceSymbols, trade);
  case 'bybit':
    return getFormattedTotal(bybitSymbols, trade);
  case 'okx':
    return getFormattedTotal(okxSymbols, trade);
  default:
    return '';
  }
};
