import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IChooseAccount } from '../interfaces';

const initialState: IChooseAccount = {
  exchanges: [],
  markets: [],
  exchange: {
    id: '',
    name: '',
    code: '',
    type: '',
  },
  market: {
    name: '',
    code: '',
    value: '',
    presition: 0,
    minNotional: 0,
  },
  tradingAmount: '',
  activeTradingPairs: 1,
  name: '',
};

export const chooseAccountSlice = createSlice({
  name: 'choose-account',
  initialState,
  reducers: {
    init: (state, action: PayloadAction<IChooseAccount>) => {
      const {
        exchanges,
        markets,
        exchange,
        market,
        tradingAmount,
        activeTradingPairs,
        name,
      } = action.payload;

      state.exchanges = exchanges;
      state.markets = markets;
      state.exchange = exchange;
      state.market = market;
      state.tradingAmount = tradingAmount;
      state.activeTradingPairs = activeTradingPairs;
      state.name = name;
    },
    setName: (state, action: PayloadAction<IChooseAccount['name']>) => {
      state.name = action.payload;
    },
    setMinNotional: (state, action: PayloadAction<IChooseAccount['market']['minNotional']>) => {
      state.market.minNotional = action.payload;
    },
    setExchanges: (state, action: PayloadAction<IChooseAccount['exchanges'][0]>) => {
      state.exchanges = action.payload;
    },
    setMarkets: (state, action: PayloadAction<IChooseAccount['markets'][0]>) => {
      state.markets = action.payload;
    },
    selectExchange: (state, action: PayloadAction<IChooseAccount['exchange']>) => {
      state.exchange = action.payload;
    },
    selectMarket: (state, action: PayloadAction<IChooseAccount['market']>) => {
      state.market = action.payload;
    },
    setTradingAmount: (state, action: PayloadAction<IChooseAccount['tradingAmount']>) => {
      state.tradingAmount = action.payload;
    },
    setActiveTradingPairs: (state, action: PayloadAction<IChooseAccount['activeTradingPairs']>) => {
      state.activeTradingPairs = action.payload;
    },
  },
});

export const {
  actions: chooseAccountActions,
} = chooseAccountSlice;
export const {
  reducer: chooseAccountReducer,
} = chooseAccountSlice;
