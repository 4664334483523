import { memo, useCallback } from 'react';
import { IPanelProps } from '../interfaces';
import { Additional, Close, Common } from '../views';

export const Panel = memo((props: IPanelProps) => {
  const getContent = useCallback(() => {
    switch (props.type) {
    case 'common':
      return (
        <Common />
      );
    case 'additional':
      return (
        <Additional />
      );
    case 'close':
      return (
        <Close />
      );
    default:
      return null;
    }
  }, [props.type]);

  return getContent();
});

Panel.displayName = 'Panel';
