import { Box } from '@mui/material';
import { Segmented } from 'antd';
import { useEffect, useState } from 'react';
import { ITabs } from '../interfaces';
import { wrapper } from '../styles';

export const Tabs = (props: ITabs) => {
  const {
    tabs,
    content,
    maxWidth,
    styles,
    defaultValue,
    segmentStyles,
    getTab,
  } = props;

  const [selected, setSelected] = useState<number>(defaultValue || 0);

  const changeTab = (value: number) => {
    setSelected(value);
    getTab?.(value);
  };

  useEffect(() => {
    if (!defaultValue) {
      return;
    }
    
    setSelected(defaultValue);
  }, [defaultValue]);

  return (
    <Box sx={wrapper(styles)}>
      <Segmented
        value={selected}
        block={true}
        style={{
          width: '100%',
          maxWidth: maxWidth || 563,
          alignSelf: 'center',
          textAlign: 'center',
          ...segmentStyles,
        }}
        size='large'
        options={tabs}
        onChange={changeTab}
      />
      
      <Box
        sx={{
          width: '100%',
        }}
      >
        {content[selected]}
      </Box>
    </Box>
  );
};
