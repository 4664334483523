import { ADA, BNB, BTC, BUSD, DOGE, DOT, ETH, LTC, UNI, USDC, XRP } from 'shared/icons';

export const coins = [
  BTC,
  ETH,
  BNB,
  ADA,
  XRP,
  USDC,
  DOGE,
  DOT,
  BUSD,
  UNI,
  LTC,
];
