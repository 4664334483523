import { SxProps, Theme } from '@mui/material';

export const BLOCK: SxProps<Theme> = {
  gap: '27px',
  // maxWidth: 178,
  maxWidth: 160,
  '&>h1': {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 'normal',
  },
  '&>span': {
    fontSize: 10,
    fontWeight: 400,
    lineHeight: 'normal',
  },
};
