import { getBaseOrder } from './get-base-order';
import { getPriceChange } from './get-price-change';

interface IGetAdditionalEntries {
  isOpened: boolean;
  segment: string;
  orders: number;
  priceChange: number;
  ordersSizeScale: number;
  ordersPriceChangeScale: number;
  tradingAmount: number;
  activeTradingPairs: number;
}
export const getAdditionalEntries = (params: IGetAdditionalEntries) => {
  const {
    isOpened,
    segment,
    orders,
    priceChange,
    ordersSizeScale,
    ordersPriceChangeScale,
    tradingAmount,
    activeTradingPairs,
  } = params;

  if (!isOpened) {
    return [];
  }

  const arraySettings = {
    length: orders,
  };

  const items = Array.from(arraySettings).map((_, index) => {
    const position = ++index;

    const priceChangeCalculated = getPriceChange({
      position,
      orders,
      ordersPriceChangeScale,
      priceChange,
      segment,
    });

    const result = getBaseOrder({
      additional: {
        isOpened,
        ordersSizeScale,
        orders,
      },
      chooseAccount: {
        tradingAmount,
        activeTradingPairs,
      },
    });

    let orderSize = 0;
    if (isFinite(result) && !isNaN(result)) {
      orderSize = result * (ordersSizeScale ** position);
    }

    return {
      position,
      priceChange: priceChangeCalculated,
      orderSize,
    };
  });

  return items;
};
