import { SxProps, Theme } from '@mui/material';

export const TAB_LABEL: SxProps<Theme> = {
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
  '&>svg': {
    width: 22,
    height: 12,
  },
};
