import { Stack } from '@mui/material';
import { Text, Title } from 'shared/ui';
import { IPresetProps } from '../interfaces';
import { BLOCK, INNER, SETTING, SETTINGS, TITLE, WRAPPER } from '../styles';

export const Preset = (props: IPresetProps) => {
  const {
    title,
    settings,
    caption,
    description,
    isSelected,
    isDesabled,
    onClick,
  } = props;

  const onClickHandler = () => {
    onClick?.();
  };

  return (
    <div onClick={onClickHandler}>
      <Stack
        sx={WRAPPER}
        className={`${title.toLowerCase()} ${isSelected ? 'active' : ''} ${isDesabled ? 'disabled' : ''}`}
      >
        <Title styles={TITLE}>
          {title}
        </Title>

        <Stack sx={INNER}>
          <Stack sx={SETTINGS}>
            {settings.display.map((setting) => (
              <Stack
                key={setting.title}
                sx={SETTING}
              >
                <Title>
                  {setting.title}
                </Title>

                <Text>
                  {setting.value}
                </Text>
              </Stack>
            ))}
          </Stack>

          <Stack sx={BLOCK}>
            <Title>
              {caption}
            </Title>

            <Text>
              {description}
            </Text>
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
};
