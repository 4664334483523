import { Box, Stack } from '@mui/material';
import { getStopLossIsOpened, getStopLossOrderType, getStopLossPriceChange, getTakeProfitOpened, getTakeProfitOrders, getTakeProfitOrdersPriceChangeScale, getTakeProfitOrdersSizeScale, getTakeProfitOrderType, getTakeProfitPriceChange, riskManagmentCloseActions } from 'entities/risk-managment-close';
import { IRiskManagmentClose } from 'entities/risk-managment-close/interfaces';
import { BlockTitle } from 'pages/trading-bots/configurator-v2/components/block-title';
import { useDispatch, useSelector } from 'react-redux';
import { Label } from 'widgets';
import { Block, CustomSlider, OrderType } from '../../../../../components';
import { CONTENT, WRAPPER } from '../styles';

export const Preset = () => {
  const dispatch = useDispatch();

  const isTakeProfitOpened = useSelector(getTakeProfitOpened);
  const isStopLossOpened = useSelector(getStopLossIsOpened);

  const takeProfitOrders = useSelector(getTakeProfitOrders);
  const takeProfitOrdersSizeScale = useSelector(getTakeProfitOrdersSizeScale);
  const takePriceChange = useSelector(getTakeProfitPriceChange);
  const takeOrderPriceChangeScale = useSelector(getTakeProfitOrdersPriceChangeScale);
  const takeOrderType = useSelector(getTakeProfitOrderType);
  
  const stopLossPriceChange = useSelector(getStopLossPriceChange);
  const stopLossOrderType = useSelector(getStopLossOrderType);

  const isOneOrder = takeProfitOrders === 1;

  const takeProfitToggleHandler = (value: boolean) => {
    dispatch(riskManagmentCloseActions.toggleTakeProfit(value));
  };

  const stopLossToggleHandler = (value: boolean) => {
    dispatch(riskManagmentCloseActions.toggleStopLoss(value));
  };

  const changeTakeProfitOrdersHandler = (value: number) => {
    dispatch(riskManagmentCloseActions.setTakeProfitOrders(value));
  };

  const changeTakeProfitOrderSizeScaleHandler = (value: number) => {
    dispatch(riskManagmentCloseActions.setTakeProfitOrdersSizeScale(value));
  };

  const changeTakeProfitPriceChangeHandler = (value: number) => {
    dispatch(riskManagmentCloseActions.setTakeProfitPriceChange(value));
  };

  const changeTakeProfitOrdersPriceChangeScaleHandler = (value: number) => {
    dispatch(riskManagmentCloseActions.setTakeProfitOrdersPriceChangeScale(value));
  };

  const changeTakeProfitOrderTypeHandler = (value: string) => {
    dispatch(riskManagmentCloseActions.setTakeProfitOrderType(value as IRiskManagmentClose['segment']['preset']['takeProfit']['orderType']));
  };

  const changeStopLossPriceChangeHandler = (value: number) => {
    dispatch(riskManagmentCloseActions.setStopLossPriceChange(value));
  };

  const changeStopLossOrderTypeHandler = (value: string) => {
    dispatch(riskManagmentCloseActions.setStopLossOrderType(value as IRiskManagmentClose['segment']['preset']['stopLoss']['orderType']));
  };

  return (
    <Stack sx={CONTENT}>
      <Block
        title={(
          <BlockTitle
            title='Take profit'
            toggle={{
              toggled: isTakeProfitOpened,
              change: takeProfitToggleHandler,
            }}
          />
        )}
      >
        {isTakeProfitOpened && (
          <Box sx={WRAPPER}>
            <CustomSlider
              title={(
                <Label
                  title='Orders'
                />
              )}
              slider={{
                value: takeProfitOrders,
                max: 50,
                marks: {
                  1: 1,
                  10: 10,
                  50: 50,
                },
                onChange: changeTakeProfitOrdersHandler,
              }}
              input={{
                value: takeProfitOrders,
                placeholder: '100%',
                onChange: changeTakeProfitOrdersHandler,
              }}
            />

            <CustomSlider
              title={(
                <Label
                  title='Orders size scale'
                />
              )}
              slider={{
                value: takeProfitOrdersSizeScale,
                max: 5,
                marks: {
                  1: 1,
                  5: 5,
                },
                onChange: changeTakeProfitOrderSizeScaleHandler,
              }}
              input={{
                value: takeProfitOrdersSizeScale,
                placeholder: '100%',
                onChange: changeTakeProfitOrderSizeScaleHandler,
              }}
              step={0.1}
              disabled={isOneOrder}
            />

            <CustomSlider
              title={(
                <Label
                  title='Price change, %'
                />
              )}
              slider={{
                value: takePriceChange,
                max: 50,
                marks: {
                  1: 1,
                  10: 10,
                  50: 50,
                },
                onChange: changeTakeProfitPriceChangeHandler,
              }}
              input={{
                value: takePriceChange,
                placeholder: '100%',
                onChange: changeTakeProfitPriceChangeHandler,
              }}
              step={0.1}
            />

            <CustomSlider
              title={(
                <Label
                  title='Orders price change scale'
                />
              )}
              slider={{
                value: takeOrderPriceChangeScale,
                max: 50,
                marks: {
                  1: 1,
                  10: 10,
                  50: 50,
                },
                onChange: changeTakeProfitOrdersPriceChangeScaleHandler,
              }}
              input={{
                value: takeOrderPriceChangeScale,
                placeholder: '100%',
                onChange: changeTakeProfitOrdersPriceChangeScaleHandler,
              }}
              step={0.1}
              disabled={isOneOrder}
            />
              
            <OrderType
              value={takeOrderType}
              onChange={changeTakeProfitOrderTypeHandler}
            />
          </Box>
        )}
      </Block>

      <Block
        title={(
          <BlockTitle
            title='Stop loss'
            toggle={{
              toggled: isStopLossOpened,
              change: stopLossToggleHandler,
            }}
          />
        )}
      >
        {isStopLossOpened && (
          <Box sx={WRAPPER}>
            <CustomSlider
              title={(
                <Label
                  title='Price change, %'
                />
              )}
              slider={{
                value: stopLossPriceChange,
                max: 50,
                marks: {
                  1: 1,
                  10: 10,
                  50: 50,
                },
                onChange: changeStopLossPriceChangeHandler,
                reversed: true,
              }}
              input={{
                value: stopLossPriceChange,
                placeholder: '100%',
                onChange: changeStopLossPriceChangeHandler,
              }}
              step={0.1}
            />
              
            <OrderType
              value={stopLossOrderType}
              onChange={changeStopLossOrderTypeHandler}
            />
          </Box>
        )}
      </Block>
    </Stack>
  );
};
