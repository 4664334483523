import { SxProps, Theme } from '@mui/material';

export const INNER: SxProps<Theme> = {
  gap: '8px',
  width: '100%',
  // maxWidth: {
  //   xs: '100%',
  //   md: 240,
  // },
  alignSelf: 'center',
};
