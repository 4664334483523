import {
  fetchBinanceExchangeInfo,
  fetchBitmartExchangeInfo,
  fetchBybitExchangeInfo,
  fetchCryptoComExchangeInfo,
  fetchGateIOExchangeInfo,
  fetchHtxExchangeInfo,
  fetchOkxExchangeInfo,
} from 'shared/helpers/exchange-symbols-request-config';

export const exchangeInfoRequests: any = {
  binance: fetchBinanceExchangeInfo,
  bybit: fetchBybitExchangeInfo,
  okx: fetchOkxExchangeInfo,
  gateio: fetchGateIOExchangeInfo,
  'crypto-com': fetchCryptoComExchangeInfo,
  demo: fetchBinanceExchangeInfo,
  bitmart: fetchBitmartExchangeInfo,
  htx: fetchHtxExchangeInfo,
};
