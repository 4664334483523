import { Box } from '@mui/material';
import { toast } from 'react-toastify';
import { renderJson, stringifyJson } from 'shared/helpers';
import { Copy as CopyIcon } from 'shared/icons';
import { Text } from 'shared/ui';
import { ICopy } from '../interfaces';
import { background, labelWrapper, wrapper } from '../styles';

export const Copy = (props: ICopy) => {
  const {
    value,
    isCopy = true,
    label,
    onlyCopy,
    display,
  } = props;

  const copyHandler = () => {
    if (!value) {
      return;
    }
    
    navigator.clipboard.writeText(stringifyJson(value));
    toast.success('Copied to clipboard', {
      autoClose: 400,
    });
  };

  const getLabel = () => {
    if (!label) {
      return null;
    }

    if (typeof label === 'string') {
      return (
        <Text
          type='secondary'
          styles={{
            fontSize: 12,
            fontWeight: 400,
          }}
        >
          {label}
        </Text>
      );
    }

    return label;
  };

  const copy = (
    <div
      style={wrapper}
      onClick={copyHandler}
    >
      {CopyIcon}
    </div>
  );

  if (onlyCopy) {
    return copy;
  }

  return (
    <Box sx={labelWrapper}>
      {getLabel()}
      
      <Box sx={background}>
        {renderJson(value, display)}

        {isCopy && copy}
      </Box>
    </Box>
  );
};
