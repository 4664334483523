import { Box } from '@mui/material';
import { Table } from '../../table';
import { WRAPPER } from '../styles';

export const BasicInfo = () => {
  return (
    <Box sx={WRAPPER}>
      <Table
        headers={[
          'Field',
          'Type',
          'Description',
        ]}
        data={[[
          'base',
          'String',
          'Symbol to trade for example ADA',
        ], [
          'quote',
          'String',
          'Market of your bot for example USDT ',
        ], [
          'position',
          'String',
          '"entry1", "entry2", etc. for entries, "close" for closure',
        ], [
          'price',
          'String',
          'Execution price, for Market orders use current price',
        ], [
          'sourceUuid',
          'String',
          'Unique identifier of your bot, see below',
        ], [
          'secretToken',
          'String',
          'Protection token for your bot, see below',
        ], [
          'timestamp',
          'String',
          'Current time in UNIX format',
        ]]}
      />
    </Box>
  );
};
