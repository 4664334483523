import { SxProps, Theme } from '@mui/material';

export const WRAPPER: SxProps<Theme> = {
  gap: '32px',
  backgroundColor: 'rgba(158, 158, 158, 0.05)',
  borderRadius: '4px',
  padding: '24px 30px',
  width: '100%',
  maxWidth: 747,
};
