import { SxProps, Theme } from '@mui/material';
import { getFontSize } from 'shared/helpers';

export const inner: SxProps<Theme> = {
  gap: '12px',
  maxWidth: 637,
  '&>span': {
    background: 'linear-gradient(90deg, #56D5A6, #084542)',
    backgroundClip: 'text',
    fontSize: getFontSize(12, 16),
    fontWeight: 400,
    color: 'transparent',
  },
};
