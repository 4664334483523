import { SxProps, Theme } from '@mui/material';

export const WRAPPER: SxProps<Theme> = {
  borderRadius: '4px',
  backgroundColor: 'rgba(46, 205, 153, 0.05)',
  gap: '8px',
  padding: '14px 28px',
  border: '1px solid transparent',
  cursor: 'pointer',
  transition: 'all .125s',
  position: 'relative',
  '&:hover, &:not([class*="disabled"])[class*="active"]': {
    borderColor: '#2ECD99',
  },
  '&[class*="disabled"]': {
    filter: 'grayscale(1)',
    opacity: 0.1,
  },
  '&[class*="conservative"]': {
    backgroundImage: 'url(/images/conservative.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '75% center',
  },
  '&[class*="aggressive"]': {
    backgroundImage: 'url(/images/aggressive.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '75% center',
  },
  '&[class*="moderate"]': {
    backgroundImage: 'url(/images/moderate.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '75% center',
  },
};
