export const UNI = (
  <svg width='120' height='120' viewBox='0 0 120 120' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M116.25 74.031L116.18 74.3124C108.314 105.216 76.9403 123.988 45.959 116.263C14.8974 108.517 -4.01217 77.0447 3.73745 45.9759L3.73758 45.9754C11.4804 14.9005 42.9508 -4.01014 74.0158 3.73599L74.0159 3.73601C105.088 11.4822 123.997 42.9579 116.25 74.031Z' fill='url(#paint0_linear_9496_20662)' stroke='#FFD9EC' strokeWidth='4'/>
    <path d='M50.53 37.0586C49.7178 36.9425 49.6791 36.9038 50.0659 36.8652C50.8008 36.7491 52.5027 36.9038 53.7018 37.1746C56.4867 37.8321 59.0009 39.5341 61.6698 42.5124L62.366 43.3247L63.3717 43.1699C67.6652 42.4737 72.0747 43.0152 75.7492 44.7171C76.7549 45.1813 78.3408 46.1096 78.5342 46.3417C78.6115 46.419 78.7276 46.9219 78.8049 47.4247C79.0757 49.2427 78.9596 50.5965 78.3795 51.6408C78.07 52.221 78.07 52.3757 78.2634 52.8786C78.4181 53.2654 78.8823 53.5361 79.3078 53.5361C80.2361 53.5361 81.2031 52.0663 81.6672 50.0163L81.8606 49.204L82.2087 49.5908C84.1814 51.7955 85.7286 54.8512 85.9607 57.0173L86.038 57.5975L85.6899 57.0947C85.1097 56.205 84.5682 55.6248 83.8333 55.122C82.5182 54.2324 81.1257 53.9616 77.4511 53.7682C74.1247 53.5748 72.2294 53.304 70.3727 52.6852C67.201 51.6408 65.5765 50.287 61.8245 45.2973C60.1613 43.0926 59.1169 41.8935 58.0726 40.8878C55.7905 38.6831 53.5084 37.5227 50.53 37.0586Z' fill='#FF007A'/>
    <path d='M79.3471 41.9703C79.4244 40.5005 79.6178 39.5335 80.0433 38.6439C80.198 38.2958 80.3527 37.9863 80.3914 37.9863C80.4301 37.9863 80.3527 38.2571 80.2367 38.5665C79.9273 39.4175 79.8886 40.6165 80.082 41.9703C80.3527 43.7109 80.4688 43.943 82.3254 45.8383C83.1764 46.7279 84.182 47.8497 84.5688 48.3138L85.2264 49.1648L84.5688 48.5459C83.7566 47.7723 81.8999 46.3025 81.4744 46.1091C81.2037 45.9544 81.165 45.9544 80.9716 46.1478C80.8169 46.3025 80.7782 46.5345 80.7782 47.6563C80.7395 49.3968 80.5075 50.4799 79.9273 51.6016C79.6178 52.1818 79.5791 52.0658 79.8499 51.4082C80.0433 50.9054 80.082 50.6733 80.082 49.0101C80.082 45.6449 79.6952 44.8326 77.3357 43.4788C76.7555 43.1307 75.7498 42.6279 75.1697 42.3571C74.5508 42.0864 74.0866 41.8543 74.1253 41.8543C74.2027 41.7769 76.4848 42.4345 77.3744 42.8213C78.7282 43.3628 78.9603 43.4015 79.115 43.3628C79.231 43.2468 79.3084 42.9373 79.3471 41.9703Z' fill='#FF007A'/>
    <path d='M52.1155 47.7339C50.4909 45.4905 49.4466 42.0093 49.6787 39.4177L49.756 38.6055L50.1428 38.6828C50.8391 38.7989 52.0381 39.263 52.6183 39.6111C54.1655 40.5395 54.8618 41.8159 55.5193 44.9876C55.7127 45.9159 55.9835 46.999 56.0995 47.3471C56.2929 47.9273 57.0278 49.2811 57.6467 50.132C58.0722 50.7509 57.8014 51.0603 56.8344 50.983C55.3646 50.8283 53.3919 49.4745 52.1155 47.7339Z' fill='#FF007A'/>
    <path d='M77.374 64.5592C69.7154 61.4648 67.0078 58.7959 67.0078 54.2704C67.0078 53.6128 67.0465 53.0713 67.0465 53.0713C67.0852 53.0713 67.3559 53.3034 67.704 53.5741C69.2512 54.8119 70.9918 55.3534 75.8268 56.0496C78.6504 56.4751 80.275 56.7845 81.7448 57.2874C86.425 58.8346 89.326 62.0063 90.0223 66.2998C90.2157 67.5375 90.0996 69.897 89.7902 71.1347C89.5194 72.1017 88.7458 73.881 88.5524 73.9197C88.5137 73.9197 88.4364 73.7263 88.4364 73.4168C88.359 71.7923 87.5467 70.2451 86.193 69.046C84.5684 67.6535 82.4797 66.6092 77.374 64.5592Z' fill='#FF007A'/>
    <path d='M71.9581 65.8356C71.8807 65.2554 71.6873 64.5205 71.5713 64.2111L71.3779 63.6309L71.726 64.0563C72.2288 64.6365 72.6156 65.3328 72.9637 66.2998C73.2345 67.0347 73.2345 67.2668 73.2345 68.4658C73.2345 69.6262 73.1958 69.897 72.9637 70.5545C72.5769 71.5989 72.1128 72.3338 71.3392 73.1461C69.9467 74.5772 68.1288 75.3508 65.5372 75.6989C65.0731 75.7376 63.758 75.8537 62.5976 75.931C59.6966 76.0857 57.7626 76.3952 56.022 77.014C55.7899 77.0914 55.5579 77.1688 55.5192 77.1301C55.4418 77.0527 56.6409 76.3565 57.6079 75.8923C58.9617 75.2348 60.3542 74.8867 63.4098 74.3451C64.9184 74.1131 66.4655 73.8036 66.8523 73.6489C70.6816 72.4498 72.5769 69.4715 71.9581 65.8356Z' fill='#FF007A'/>
    <path d='M75.4786 72.0631C74.4729 69.8583 74.2408 67.7696 74.7823 65.797C74.8597 65.6036 74.9371 65.4102 75.0144 65.4102C75.0918 65.4102 75.3239 65.5262 75.5559 65.6809C76.0201 65.9904 76.9871 66.5319 79.4626 67.8857C82.5956 69.5876 84.3749 70.9027 85.6127 72.4112C86.6957 73.7263 87.3533 75.2348 87.6627 77.0914C87.8561 78.1358 87.7401 80.65 87.4693 81.6943C86.6183 84.9821 84.6844 87.6123 81.8607 89.1208C81.4353 89.3529 81.0871 89.5076 81.0485 89.5076C81.0098 89.5076 81.1645 89.1208 81.3966 88.6567C82.3249 86.684 82.4409 84.7887 81.7447 82.6613C81.3192 81.3462 80.4296 79.7603 78.6503 77.0914C76.5229 73.9971 76.0201 73.1848 75.4786 72.0631Z' fill='#FF007A'/>
    <path d='M46.6619 83.8992C49.5242 81.5011 53.044 79.7992 56.2931 79.2577C57.6856 79.0256 60.0064 79.1029 61.2828 79.4511C63.3328 79.9926 65.1895 81.153 66.1565 82.5841C67.0848 83.9766 67.5103 85.1757 67.9357 87.8446C68.0905 88.8889 68.2839 89.9719 68.3225 90.204C68.632 91.5965 69.2508 92.6795 70.0244 93.2597C71.2235 94.1494 73.3122 94.188 75.3622 93.4144C75.7104 93.2984 76.0198 93.1824 76.0198 93.221C76.0972 93.2984 75.0528 93.9946 74.3566 94.3428C73.3896 94.8456 72.616 95.0003 71.5716 95.0003C69.715 95.0003 68.1291 94.0333 66.8527 92.0993C66.5819 91.7125 66.0404 90.5908 65.5763 89.5465C64.2225 86.4134 63.5262 85.4851 61.9404 84.4407C60.5479 83.5511 58.7686 83.3577 57.4148 84.0153C55.6356 84.8662 55.1714 87.1483 56.4092 88.5408C56.912 89.121 57.8403 89.5851 58.6139 89.7012C60.0451 89.8946 61.2828 88.7729 61.2828 87.3417C61.2828 86.4134 60.9347 85.8719 60.0064 85.4464C58.7686 84.9049 57.4148 85.5238 57.4535 86.7228C57.4535 87.2257 57.6856 87.5351 58.1884 87.7672C58.4979 87.9219 58.4979 87.9219 58.2658 87.8832C57.1441 87.6512 56.8733 86.2587 57.763 85.3691C58.846 84.286 61.1281 84.7502 61.9017 86.2587C62.2111 86.8776 62.2498 88.1153 61.9791 88.8889C61.3215 90.5908 59.4649 91.4805 57.5696 90.9776C56.2931 90.6295 55.7516 90.2814 54.2044 88.6955C51.4969 85.9106 50.4525 85.3691 46.5845 84.7889L45.8496 84.6728L46.6619 83.8992Z' fill='#FF007A'/>
    <path fillRule='evenodd' clipRule='evenodd' d='M26.3168 21.6632C35.3292 32.6096 49.2152 49.6287 49.9115 50.557C50.4917 51.3306 50.2596 52.0655 49.2926 52.607C48.7511 52.9165 47.6294 53.2259 47.0878 53.2259C46.469 53.2259 45.7341 52.9165 45.2312 52.4136C44.8831 52.0655 43.3746 49.8608 39.9708 44.5616C37.3792 40.5003 35.1745 37.1351 35.1358 37.0964C34.9811 37.0191 34.9811 37.0191 39.7 45.4513C42.6784 50.7504 43.6454 52.6457 43.6454 52.8778C43.6454 53.3806 43.4906 53.6514 42.8718 54.3476C41.8274 55.508 41.3632 56.8231 41.0151 59.5694C40.6283 62.6251 39.584 64.7911 36.6056 68.4657C34.8651 70.6318 34.5943 71.0186 34.1688 71.9082C33.6273 72.9912 33.4726 73.6101 33.3952 75.0026C33.3179 76.4724 33.4726 77.4007 33.8981 78.7932C34.2849 80.0309 34.7103 80.8432 35.7547 82.4291C36.6443 83.8216 37.1858 84.8659 37.1858 85.2527C37.1858 85.5621 37.2632 85.5621 38.6557 85.2527C41.9821 84.4791 44.7284 83.164 46.2369 81.5395C47.1652 80.5338 47.3973 79.9923 47.3973 78.5998C47.3973 77.7102 47.3586 77.5168 47.1265 76.9752C46.7397 76.1243 46.0048 75.4281 44.4189 74.345C42.3302 72.9139 41.4406 71.7535 41.2085 70.2063C41.0151 68.8912 41.2472 68.0015 42.4076 65.5647C43.6067 63.0505 43.9161 62.0062 44.1095 59.4533C44.2255 57.8288 44.4189 57.1712 44.8831 56.6684C45.3859 56.1269 45.8114 55.9335 47.0105 55.7788C48.9832 55.508 50.2596 55.0052 51.2653 54.0382C52.1549 53.2259 52.5417 52.4136 52.5804 51.2145L52.619 50.3249L52.1162 49.7834C50.2983 47.6947 25.1178 20 25.0017 20C24.963 20 25.5819 20.7349 26.3168 21.6632ZM38.1915 76.6658C38.617 75.9309 38.3849 75.0026 37.6887 74.5384C37.0311 74.1129 36.0254 74.3063 36.0254 74.8865C36.0254 75.0413 36.1028 75.196 36.3349 75.2733C36.683 75.4667 36.7217 75.6601 36.4509 76.0856C36.1802 76.5111 36.1802 76.8979 36.5283 77.1686C37.0698 77.5941 37.8047 77.362 38.1915 76.6658Z' fill='#FF007A'/>
    <path fillRule='evenodd' clipRule='evenodd' d='M53.857 56.3201C52.9287 56.5909 52.0391 57.5966 51.7683 58.6022C51.6136 59.2211 51.6909 60.3428 51.9617 60.6909C52.3872 61.2325 52.774 61.3872 53.857 61.3872C55.9844 61.3872 57.8023 60.4589 57.9957 59.3372C58.1891 58.4088 57.3769 57.1324 56.2552 56.5522C55.675 56.2428 54.4759 56.1267 53.857 56.3201ZM56.3325 58.2541C56.642 57.79 56.5259 57.2871 55.9457 56.939C54.9014 56.2815 53.3155 56.823 53.3155 57.8286C53.3155 58.3315 54.1278 58.873 54.9014 58.873C55.4042 58.873 56.1004 58.5636 56.3325 58.2541Z' fill='#FF007A'/>
    <defs>
      <linearGradient id='paint0_linear_9496_20662' x1='0' y1='0' x2='0' y2='120' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#FEF3F9'/>
        <stop offset='1' stopColor='#FDE5F2'/>
      </linearGradient>
    </defs>
  </svg>
);
