export const ETH = (
  <svg width='120' height='120' viewBox='0 0 120 120' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='60' cy='60' r='60' fill='#EDF0F4'/>
    <path d='M59.8367 24L59.3594 25.6213V72.663L59.8367 73.1392L81.6725 60.2318L59.8367 24Z' fill='#343434'/>
    <path d='M59.8364 24L38 60.2318L59.8364 73.1392V50.3064V24Z' fill='#8C8C8C'/>
    <path d='M59.8354 77.2737L59.5664 77.6017V94.3587L59.8354 95.144L81.6846 64.373L59.8354 77.2737Z' fill='#3C3C3B'/>
    <path d='M59.8364 95.144V77.2737L38 64.373L59.8364 95.144Z' fill='#8C8C8C'/>
    <path d='M59.8359 73.1394L81.6717 60.2321L59.8359 50.3066V73.1394Z' fill='#141414'/>
    <path d='M38 60.2321L59.8364 73.1394V50.3066L38 60.2321Z' fill='#393939'/>
  </svg>
);
