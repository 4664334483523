import { Stack } from '@mui/material';
import { getCurrentBot } from 'entities/bot/model/selectors/get-current-bot/get-current-bot';
import { getIsOpened, getSegment, riskManagmentAdditionalActions } from 'entities/risk-managment-additional';
import { IRiskManagmentAdditional } from 'entities/risk-managment-additional/interfaces';
import { getAdditionalEntryOrdersValue } from 'pages/trading-bots/configurator-v2/helpers';
import { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Block } from '../../../components';
import { BlockTitle } from '../../../components/block-title';
import { Panel } from '../../configure-risk-managment/components';
import { WRAPPER } from '../styles';
import { Alerts, Preset } from '../tabs';

export const AdditionalEntry = () => {
  const bot = useSelector(getCurrentBot);
  const isOpened = useSelector(getIsOpened);
  const segment = useSelector(getSegment);

  const dispatch = useDispatch();

  const toggleHandler = useCallback((value: boolean) => {
    dispatch(riskManagmentAdditionalActions.toggle(value));
  }, [dispatch]);

  const changeSegmentHandler = useCallback((value: string) => {
    dispatch(riskManagmentAdditionalActions.changeSegment(value as IRiskManagmentAdditional['segment']['value']));
  }, [dispatch]);

  const getContent = useCallback((): ReactNode => {
    if (!isOpened) {
      return null;
    }

    switch (segment) {
    case 'preset':
      return (
        <Preset />
      );
    case 'alert':
      return (
        <Alerts />
      );
    default:
      return null;
    }
  }, [isOpened, segment]);

  const getSegments = useMemo(() => {
    if (!isOpened) {
      return undefined;
    }

    return {
      selected: segment as string,
      options: [{
        label: 'Preset',
        value: 'preset',
      }, {
        label: 'Alerts',
        value: 'alert',
      }],
      change: changeSegmentHandler,
    };
  }, [isOpened, segment, changeSegmentHandler]);

  useEffect(() => {
    if (!bot?.botUuid) {
      return;
    }

    dispatch(riskManagmentAdditionalActions.init(getAdditionalEntryOrdersValue(bot)));
  }, [bot?.botUuid, dispatch]);

  return (
    <Stack sx={WRAPPER}>
      <Block
        title={(
          <BlockTitle
            title='Additional entry orders'
            toggle={{
              toggled: isOpened,
              change: toggleHandler,
            }}
            segment={getSegments}
          />
        )}
        maxWidth={segment === 'alert' ? 400 : 0}
      >
        {getContent()}
      </Block>

      {isOpened && (
        <Panel type='additional' />
      )}
    </Stack>
  );
};
