import { SxProps, Theme } from '@mui/material';

export const DEFAULT_ICON: SxProps<Theme> = {
  width: '30px',
  height: '30px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textTransform: 'uppercase',
};
