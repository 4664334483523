import { SxProps, Theme } from '@mui/material';

export const DESCRIPTION: SxProps<Theme> = {
  '&,&>*': {
    fontSize: 9,
    fontWeight: 400,
    lineHeight: 'normal',
    color: 'rgba(58, 58, 60, 0.70)',
    '&>a': {
      fontSize: 9,
      fontWeight: 400,
      color: '#2ECD99',
      textDecoration: 'underline',
      display: 'inline-flex',
      alignItems: 'center',
      gap: '4px',
      '&>svg': {
        width: 8,
        height: 8,
      },
    },
  },
};
