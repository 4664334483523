import { Stack } from '@mui/material';
import { IPlayerProps } from '../interfaces';
import { WRAPPER } from '../styles';

export const Player = (props: IPlayerProps) => {
  const {
    url,
  } = props;

  // const [src, setSrc] = useState<string | null>(null);

  // const loadFile = async () => {
  //   try {
  //     await delay(5000);
  //     const video = await fetch(url);
  //     console.log('video', video);
  //     // setSrc(video)
  //     const src = URL.createObjectURL(await video.blob());
  //     setSrc(src);
  //   } catch (error) {
  //     console.log('error', error);
  //   }
  // };

  // useEffect(() => {
  //   if (!url) {
  //     return;
  //   }

  //   loadFile();
  // }, [url]);
  
  return (
    <Stack sx={WRAPPER}>
      {/* {!src ? (
        <Loader isContentOverflow={truncate} />
      ) : (
      )} */}
      <video
        src={url}
        autoPlay={true}
        muted={true}
        loop={true}
      />
    </Stack>
  );
};
