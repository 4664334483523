import { SxProps, Theme } from '@mui/material';

export const WRAPPER: SxProps<Theme> = {
  gap: '8px',
  flex: 1,
  width: '100%',
  height: 106,
  maxWidth: {
    xs: '100%',
    md: 400,
  },
};
