import { SxProps, Theme } from '@mui/material';

export const tip: SxProps<Theme> = {
  width: 20,
  height: 20,
  '&>svg': {
    width: '100%',
    height: '100%',
  },
};
