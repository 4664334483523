import { generateSecretToken } from 'shared/helpers';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';
import { sourceUuid } from './source-uuid';

export const secretToken = (exchangeAccountUuid: string) => {
  return generateSecretToken({
    exchangeAccountUuid,
    skyrexUuid: getSkyrexUuid()!,
    sourceUuid,
  });
};
