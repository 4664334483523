import { SxProps, Theme } from '@mui/material';

export const INNER: SxProps<Theme> = {
  flexDirection: 'row',
  alignItems: 'center',
  gap: '14px',
  '&>div:first-of-type': {
    flex: 1,
  },
};
