interface IGetBaseOrder {
  additional: {
    isOpened: boolean;
    ordersSizeScale: number;
    orders: number;
  };
  chooseAccount: {
    tradingAmount: number;
    activeTradingPairs: number;
  };
}
export const getBaseOrder = (params: IGetBaseOrder) => {
  const {
    additional,
    chooseAccount,
  } = params;

  const orderSizeScale = additional.ordersSizeScale;
  const isAdditionalEntryOrdersOpened = additional.isOpened;

  const tradingAmount = chooseAccount.tradingAmount;
  const activeTradingPairs = chooseAccount.activeTradingPairs;

  const additionalEntryOrdersValue = isAdditionalEntryOrdersOpened ? additional.orders : 0;
  if (orderSizeScale === 1) {
    return tradingAmount / (activeTradingPairs * (1 + additionalEntryOrdersValue));
  }

  return (tradingAmount * (1 - orderSizeScale)) / (activeTradingPairs * (1 - orderSizeScale ** (1 + additionalEntryOrdersValue)));
};
