import { SxProps, Theme } from '@mui/material';

export const WRAPPER: SxProps<Theme> = {
  flexDirection: {
    xs: 'column',
    md: 'row',
  },
  alignItems: {
    xs: 'initial',
    md: 'flex-start',
  },
  justifyContent: 'space-between',
  gap: '24px',
};
