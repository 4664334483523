import { SxProps, Theme } from '@mui/material';

export const right: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  maxWidth: 480,
  '&>img': {
    width: '100%',
    height: '100%',
  },
};
