import { Player } from '@lottiefiles/react-lottie-player';
import { Box, Stack } from '@mui/material';
import { Divider } from 'antd';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Title } from 'shared/ui';
import { IBasicInfoProps } from '../interfaces';
import { BUTTONS, BUTTONS_INNER, DIVIDER, INNER, LINK, TITLE, WRAPPER } from '../styles';

export const BasicInfo = (props: IBasicInfoProps) => {
  const getLink = (link: IBasicInfoProps['links'][0]) => {
    if (link.type === 'router-link') {
      return (
        <RouterLink
          to={link.url}
          style={LINK}
          target='_blank'
          rel='noreferer'
        >
          {link.title}
        </RouterLink>
      );
    }

    return (
      <Link
        type='success'
        href={link.url}
        rel='noreferer'
        target='_blank'
        styles={LINK}
      >
        {link.title}
      </Link>
    );
  };

  return (
    <Box sx={WRAPPER}>
      <Stack sx={INNER}>
        <Title styles={TITLE}>{props.title}</Title>

        <Stack sx={BUTTONS}>
          <Divider style={DIVIDER} />

          <Box sx={BUTTONS_INNER}>
            {props.links.map((link) => getLink(link))}
          </Box>
        </Stack>
      </Stack>

      <Player
        autoplay
        loop
        src='/videos/alert-bot.json'
        style={{
          width: '100%',
          height: 165,
          maxWidth: 365,
        }}
      />
    </Box>
  );
};
