import { Box, Stack } from '@mui/material';
import { exchangeIcons, IExchangeIcons } from 'shared/consts/exchange-icons';
import { Title } from 'shared/ui';
import { IExchangeProps } from '../interfaces';
import { INNER, LABEL, TITLE, WRAPPER } from '../styles';

export const Exchange = (props: IExchangeProps) => {
  return (
    <Stack sx={WRAPPER}>
      <Stack sx={INNER}>
        <Box>
          {exchangeIcons[(props.code || 'demo') as keyof IExchangeIcons]}
        </Box>

        <Title styles={TITLE}>
          {props.title}
        </Title>
      </Stack>

      <Box sx={LABEL}>
        {props.label}
      </Box>
    </Stack>
  );
};
