interface IGetTakeProfitValume {
  orders: number;
  ordersSizeScale: number;
  position: number;
}
export const getTakeProfitValume = (params: IGetTakeProfitValume) => {
  const {
    orders,
    ordersSizeScale,
    position,
  } = params;

  const calculatedOrdersSizeScale = orders === 1 ? 1 : ordersSizeScale;
  if (calculatedOrdersSizeScale === 1) {
    return (100 / orders);
  }

  return ((100 * (1 - calculatedOrdersSizeScale) / (1 - (calculatedOrdersSizeScale ** orders))) * calculatedOrdersSizeScale ** (position - 1));
};
