import { IChooseSource } from 'entities/choose-source/interfaces';
import { BotData } from 'pages/trading-bots/my-bot/ui/my-bot';

export const getChooseSourceValue = (bot: BotData) => {
  const value = {
    error: '',
    alertSource: bot.signalSource || 'indicator',
    orderType: bot.baseOrderType.toLowerCase() as IChooseSource['orderType'] || 'market',
  };

  return value;
};
