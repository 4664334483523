interface IGetActiveTradingPairsError {
  tradingAmount: number;
  activeTradingPairs: number;
  minNotional: number;
}
export const getActiveTradingPairsError = (params: IGetActiveTradingPairsError) => {
  const {
    tradingAmount,
    activeTradingPairs,
    minNotional,
  } = params;

  if ((+tradingAmount / activeTradingPairs) < minNotional) {
    return 'Order size is less than minimal';
  }

  return '';
};
