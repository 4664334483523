interface IGetTradingAmountError {
  amount: number;
  minNotional: number;
}
export const getTradingAmountError = (params: IGetTradingAmountError) => {
  const {
    amount,
    minNotional,
  } = params;

  if (+amount > 0 && +amount < minNotional) {
    return 'Order size is less than minimal';
  }

  return '';
};
