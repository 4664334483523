import { SxProps, Theme } from '@mui/material';

export const wrapper: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  padding: '12px 16px',
  backgroundColor: '#fff',
  borderRadius: '22px',
  boxShadow: '0px 2px 16.6px rgba(0, 0, 0, 0.09)',
  width: '100%',
  // maxWidth: 340,
};
