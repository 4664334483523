interface IGetTakeProfitPriceChange {
  orders: number;
  priceChange: number;
  ordersPriceChangeScale: number;
  position: number;
}
export const getTakeProfitPriceChange = (params: IGetTakeProfitPriceChange) => {
  const {
    orders,
    priceChange,
    ordersPriceChangeScale,
    position,
  } = params;

  const orderPriceChangeScale = orders === 1 ? 1 : ordersPriceChangeScale;
  if (orderPriceChangeScale === 1) {
    return priceChange * position;
  }

  return priceChange * ((1 - (orderPriceChangeScale ** position)) / (1 - orderPriceChangeScale));
};
