import { Stack } from '@mui/material';
import { Divider } from 'antd';
import { getActiveTradingPairs, getMarket, getTradingAmount } from 'entities/choose-account';
import { getPreset, riskManagmentAutoActions } from 'entities/risk-managment-auto';
import { presets } from 'pages/trading-bots/configurator-v2/consts';
import { getAdditionalEntries } from 'pages/trading-bots/configurator-v2/helpers/get-additional-entries';
import { Fragment, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { calibrateEntryOrdersParameters } from 'shared/helpers/calibrateAutoParams';
import { currencyFormatter } from 'shared/helpers/currency-formatter';
import { Block } from '../../../../block';
import { WRAPPER } from '../../../styles';

const divider = (
  <Divider
    style={{
      margin: 0,
    }}
  />
);

export const Common = () => {
  const dispatch = useDispatch();

  const tradingAmount = useSelector(getTradingAmount);
  const activeTradingPairs = useSelector(getActiveTradingPairs);
  const market = useSelector(getMarket);

  const selectedPreset = useSelector(getPreset);

  const {
    result,
    failureDetails,
    item,
  } = useMemo(() => {
    const item = presets.find((preset) => preset.title.toLowerCase() === selectedPreset)!;

    if (!item) {
      return {
        result: null,
        item: null,
      };
    }

    const itemSettingsValues = item.settings.values;

    const {
      result,
      failureDetails,
      bestMatchingParameters,
    } = calibrateEntryOrdersParameters({
      tradingAmount: +tradingAmount,
      activeTradingPairs: activeTradingPairs,

      minNotional: market.minNotional,

      overlap: itemSettingsValues.overlap,
      initialAdditionalOrderSizeScale: itemSettingsValues.initialAdditionalOrderSizeScale,
      initialAdditionalOrderPriceChange: itemSettingsValues.initialAdditionalOrderPriceChange,
      initialAdditionalOrderPriceChangeScale: itemSettingsValues.initialAdditionalOrderPriceChangeScale,
      initialMaxAdditionalOrders: itemSettingsValues.initialMaxAdditionalOrders,
      stopLossPercent: itemSettingsValues.stopLossPercent,
      
      maxIterations: 1000,
      percentRange: 20,
      iterationStep: 0.005,
      overlapDifferenceTolerance: 1,
      alpha: 0.001,
      globalSearchSamples: 50,
    });

    dispatch(riskManagmentAutoActions.setValues({
      additionalOrderPriceChange: result?.calibratedAdditionalOrderPriceChange || bestMatchingParameters?.calibratedAdditionalOrderPriceChange || 0,
      additionalOrderPriceChangeScale: result?.calibratedAdditionalOrderPriceChangeScale || bestMatchingParameters?.calibratedAdditionalOrderPriceChangeScale || 0,
      additionalOrderSizeScale: result?.calibratedAdditionalOrderSizeScale || bestMatchingParameters?.calibratedAdditionalOrderSizeScale || 0,
      maxAdditionalOrders: result?.calibratedMaxAdditionalOrders || bestMatchingParameters?.calibratedMaxAdditionalOrders || 0,
      stopLossPercent: result?.stopLossPercent || bestMatchingParameters?.stopLossPercent || 0,
      taketProfitPercent: itemSettingsValues.takeProfitPercent || 0,    
      baseOrderSize: result?.baseOrderSize || bestMatchingParameters?.baseOrderSize || 0,
    }));

    return {
      result: result || bestMatchingParameters,
      item,
      failureDetails,
    };
  }, [selectedPreset, tradingAmount, activeTradingPairs, market]);

  const items = useMemo(() => {
    if (!result) {
      return [];
    }

    const entries = getAdditionalEntries({
      isOpened: true,
      segment: 'auto',
      orders: result.calibratedMaxAdditionalOrders,
      priceChange: result.calibratedAdditionalOrderPriceChange,
      ordersSizeScale: result.calibratedAdditionalOrderSizeScale,
      ordersPriceChangeScale: result.calibratedAdditionalOrderPriceChangeScale,
      tradingAmount: +tradingAmount,
      activeTradingPairs: activeTradingPairs,
    });

    const formatedItems = entries.map((item) => {
      return [{
        value: item.position,
      }, {
        value: `${currencyFormatter(item.orderSize, market.presition)} ${market.code}`,
      }, {
        value: `${item.priceChange}%`,
      }];
    });

    return formatedItems;
  }, [
    result,
    tradingAmount,
    activeTradingPairs,
    market,
  ]);

  useEffect(() => {
    dispatch(riskManagmentAutoActions.setError(failureDetails?.length ? failureDetails[0] : ''));
  }, [failureDetails, dispatch]);

  return (
    <Stack
      sx={{
        ...WRAPPER,
        maxWidth: {
          xs: '100%',
          md: 433,
        },
      }}
    >
      <Block
        title='Entry order'
        error={failureDetails?.[0] || ''}
        layout='row'
        content={[{
          title: 'Open order size',
          value: result?.baseOrderSize ? `${result?.baseOrderSize} ${market.code}`: '',
        }]}
      />


      {!!items.length && (
        <Fragment>
          {divider}
          
          <Block
            title='Additional entry orders'
            layout='table'
            error={failureDetails?.[1] || ''}
            content={{
              headers: [{
                label: '№',
                key: 'order',
              }, {
                label: 'Order size',
                key: 'order-size',
              }, {
                label: 'Price change',
                key: 'price-change',
              }],
              items: items as any,
            }}
          />
        </Fragment>
      )}

      {divider}

      <Block
        layout='row'
        content={[{
          title: 'Full position per coin',
          value: result?.fullPositionSize ? `${result?.fullPositionSize} ${market.code}` : '',
        }]}
      />

      <Block
        title='Close orders'
        layout='row'
        content={[null, {
          title: 'Take profit',
          value: item?.settings.values.takeProfitPercent ? `${item?.settings.values.takeProfitPercent}%` : '',
        }, result?.stopLossPercent ? {
          title: 'Stop loss',
          value: result?.stopLossPercent ? `${result?.stopLossPercent}%` : '',
        } : null]}
      />
    </Stack>
  );
};
