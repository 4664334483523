import { Box, Stack } from '@mui/material';
import { SecondaryInfo } from 'shared/icons';
import { Title, Tooltip } from 'shared/ui';
import { ILabelProps } from '../interfaces';
import { DESCRIPTION, TITLE, WRAPPER } from '../styles';

export const Label = (props: ILabelProps) => {
  return (
    <Box sx={WRAPPER}>
      <Stack gap={1}>
        <Title styles={TITLE}>
          {props.title}
        </Title>

        {props.desciption && (
          <Box sx={DESCRIPTION}>
            {props.desciption}
          </Box>
        )}
      </Stack>

      {props.tooltip && (
        <Tooltip title={props.tooltip}>
          {SecondaryInfo}
        </Tooltip>
      )}
    </Box>
  );
};
