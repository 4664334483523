import { AxiosInstance, AxiosResponse, AxiosError, InternalAxiosRequestConfig } from 'axios';
import { getAuthToken, getRefreshToken, setAuthToken, setRefreshToken, setSkyrexUuid } from 'shared/helpers/storage-helper';
import { handleUnauthorized } from './helpers/handle-unauhorize';

let isRefreshing = false;
let failedQueue: { resolve: (value?: string | AxiosResponse<any, any> | undefined) => void; reject: (reason?: any) => void; }[] = [];

const processQueue = (error: AxiosError | null, token: string | null = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token!);
    }
  });
  failedQueue = [];
};

export function setupResponseInterceptors(axiosInstance: AxiosInstance): void {
  axiosInstance.interceptors.response.use(
    async (response: AxiosResponse) => {
      const originalRequest = response.config as InternalAxiosRequestConfig & { _retry?: boolean; };

      if (response.data && response.data.data && response.data.data.statusCode === 401 && originalRequest.url !== '/user/refreshToken') {
        
        if (!originalRequest._retry) {
          originalRequest._retry = true;

          if (isRefreshing) {
            return new Promise<AxiosResponse>((resolve, reject) => {
              failedQueue.push({
                //@ts-ignore
                resolve,
                reject,
              });
            }).then(token => {
              if (token && typeof token === 'string') {
                originalRequest.headers.Authorization = `Bearer ${token}`;
                return axiosInstance(originalRequest);
              }
              return Promise.reject(response);
            });
          }

          isRefreshing = true;

          try {
            const refreshTokenFromStorage = getRefreshToken();
            if (!refreshTokenFromStorage) {
              handleUnauthorized();
              return Promise.reject(response);
            }

            const responseFromRefresh = await axiosInstance.request(
              {
                url: '/user/refreshToken',
                method: 'post',
                headers: {
                  Authorization: `Bearer ${refreshTokenFromStorage}`,
                },
              },
            ) as AxiosResponse<{ success: boolean; data: { accessToken: string; refreshToken: string; skyrexUserUuid: string; }; }>;

            if (!responseFromRefresh.data.success || !responseFromRefresh.data.data.accessToken || !responseFromRefresh.data.data.refreshToken) {
              handleUnauthorized();
              return Promise.reject(response);
            }

            const {
              accessToken,
              refreshToken,
              skyrexUserUuid,
            } = responseFromRefresh.data.data;

            setRefreshToken(refreshToken);
            setAuthToken(accessToken);
            setSkyrexUuid(skyrexUserUuid);

            originalRequest.headers.Authorization = `Bearer ${accessToken}`;
            processQueue(null, accessToken);
            return axiosInstance(originalRequest);
          } catch (err) {
            processQueue(err as AxiosError, null);
            handleUnauthorized();
            return Promise.reject(err);
          } finally {
            isRefreshing = false;
          }
        }
      }

      return response;
    },
    (error: AxiosError) => {
      if (error.response && error.response.status === 401) {
        handleUnauthorized();
      }
      return Promise.reject(error);
    },
  );
}

export function setupRequestInterceptors(axiosInstance: AxiosInstance): void {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = getAuthToken();
      if (config.url !== '/user/refreshToken') {
        config.headers.Authorization = `Bearer ${token ?? ''}`;
      }
      return config;
    },
    (error) => Promise.reject(error),
  );
}
