import { ExternalLink } from 'shared/icons';
import { Link } from 'shared/ui';

export const TradingViewLink = () => {
  return (
    <Link
      type='success'
      href='https://www.tradingview.com/chart/'
      target='_blank'
      rel='noreferrer'
    >
      TradingView
      {ExternalLink}
    </Link>
  );
};
