import { SxProps, Theme } from '@mui/material';

export const WRAPPER: SxProps<Theme> = {
  backgroundColor: 'rgba(46, 205, 153, 0.03)',
  borderRadius: '4px',
  padding: '26px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: {
    xs: '24px',
    lg: '40px',
  },
  flex: 1,
  width: '100%',
  maxWidth: 747,
  flexDirection: {
    xs: 'column-reverse',
    lg: 'row',
  },
};
