import { SxProps, Theme } from '@mui/material';
import { getFontSize } from 'shared/helpers';

export const title: SxProps<Theme> = {
  '&>span': {
    fontSize: getFontSize(14, 24),
    fontWeight: 400,
    color: '#707070',
    lineHeight: 'normal',
  },
};
