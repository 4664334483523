import { Stack } from '@mui/material';
import { Segmented } from 'antd';
import { getCurrentBot } from 'entities/bot/model/selectors/get-current-bot/get-current-bot';
import { getExchange } from 'entities/choose-account';
import { chooseSourceActions, getAlertSource, getOrderType } from 'entities/choose-source';
import { IChooseSource } from 'entities/choose-source/interfaces';
import { getChooseSourceValue } from 'pages/trading-bots/configurator-v2/helpers';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Label, Tabs } from 'widgets';
import { secretToken, sourceUuid } from 'shared/consts';
import { TabIcon } from 'shared/icons';
import { Player, StepBlock } from '../../../components';
import { INNER, LABEL_WRAPPER, TAB_LABEL, WRAPPER } from '../styles';
import { Custom, Indicator, Strategy } from '../tabs';
import { BasicInfo } from '../tabs/custom/components';

const defaultValue = {
  indicator: 0,
  strategy: 1,
  custom: 2,
};

export const ChooseSource = memo(() => {
  console.log('2', 2);
  const bot = useSelector(getCurrentBot);
  const exchange = useSelector(getExchange);

  const alertSource = useSelector(getAlertSource);
  const orderType = useSelector(getOrderType);

  const dispatch = useDispatch();

  const token = useMemo(() => {
    if (!exchange) {
      return '';
    }

    const generatedToken = secretToken(exchange.id);
    return generatedToken;
  }, [exchange]);

  const selectAlertSourceHandler = useCallback((tab: number) => {
    let value = '';
    switch (tab) {
    case 0:
      value = 'indicator';
      break;
    case 1:
      value = 'strategy';
      break;
    case 2:
      value = 'custom';
      break;
    default:
      break;
    }

    dispatch(chooseSourceActions.selectAlertSource(value as IChooseSource['alertSource']));
  }, [dispatch]);

  const selectOrderTypeHandler = useCallback((value: string) => {
    dispatch(chooseSourceActions.selectOrderType(value as IChooseSource['orderType']));
  }, [dispatch]);

  const getContent = useCallback(() => {
    if (alertSource === 'custom') {
      return (
        <BasicInfo />
      );
    }

    return (
      <Player url={`/videos/${alertSource}.mp4`} />
    );
  }, [alertSource]);

  const getTableLabel = useCallback((label: string) => {
    return (
      <Stack sx={TAB_LABEL}>
        {TabIcon}

        {label}
      </Stack>
    );
  }, []);

  useEffect(() => {
    if (!bot?.botUuid) {
      return;
    }

    dispatch(chooseSourceActions.init(getChooseSourceValue(bot)));
  }, [bot?.botUuid, dispatch]);

  return (
    <Stack sx={WRAPPER}>
      <StepBlock title='Step 2 - Choose source and open order type'>
        <Stack sx={INNER}>
          <Stack sx={LABEL_WRAPPER}>
            <Label
              title='Alerts source'
            />
          
            <Tabs
              defaultValue={defaultValue[alertSource]}
              tabs={[{
                label: getTableLabel('Indicator'),
                value: 0,
              }, {
                label: getTableLabel('Strategy'),
                value: 1,
              }, {
                label: 'Custom',
                value: 2,
              }]}
              getTab={selectAlertSourceHandler}
              content={[(
                <Indicator
                  secretToken={token}
                  sourceUuid={sourceUuid}
                  position={1}
                />
              ), (
                <Strategy
                  secretToken={token}
                  sourceUuid={sourceUuid}
                  position={1}
                />
              ), (
                <Custom
                  secretToken={token}
                  sourceUuid={sourceUuid}
                  position={1}
                />
              )]}
              styles={{
                gap: '28px',
              }}
            />
          </Stack>

          <Stack sx={LABEL_WRAPPER}>
            <Label
              title='Order type'
            />

            <Segmented
              value={orderType}
              options={[{
                label: 'Market',
                value: 'market',
              }, {
                label: 'Limit',
                value: 'limit',
              }]}
              style={{
                maxWidth: 'max-content',
              }}
              onChange={selectOrderTypeHandler}
            />
          </Stack>
        </Stack>
      </StepBlock>

      {getContent()}
    </Stack>
  );
});

ChooseSource.displayName = 'ChooseSource';
