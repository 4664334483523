import { Stack } from '@mui/material';
import { getCurrentBot } from 'entities/bot/model/selectors/get-current-bot/get-current-bot';
import { getExchange } from 'entities/choose-account';
import { getAlertSource } from 'entities/choose-source';
import { getSegment, riskManagmentCloseActions } from 'entities/risk-managment-close';
import { IRiskManagmentClose } from 'entities/risk-managment-close/interfaces';
import { Block, Player } from 'pages/trading-bots/configurator-v2/components';
import { BlockTitle } from 'pages/trading-bots/configurator-v2/components/block-title';
import { getCloseOrdersValue } from 'pages/trading-bots/configurator-v2/helpers';
import { ReactNode, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Panel } from '../../configure-risk-managment/components';
import { WRAPPER } from '../styles';
import { Alerts, Preset } from '../tabs';

export const CloseOrders = () => {
  const dispatch = useDispatch();

  const bot = useSelector(getCurrentBot);
  const segment = useSelector(getSegment);

  const alertSource = useSelector(getAlertSource);
  const exchange = useSelector(getExchange);

  const changeSegmentHandler = useCallback((value: string) => {
    dispatch(riskManagmentCloseActions.changeSegment(value as IRiskManagmentClose['segment']['value']));
  }, []);
  
  const getContent = useCallback((): ReactNode => {
    switch (segment) {
    case 'preset':
      return (
        <Preset />
      );
    case 'alert':
      return (
        <Alerts
          alertSource={alertSource}
          exchangeAccountUuid={exchange.id}
        />
      );
    default:
      return null;
    }
  }, [segment, alertSource, exchange]);

  const getBlock = useCallback(() => {
    if (segment === 'preset') {
      return (
        <Panel type='close' />
      );
    }

    return (
      <Player url={`/videos/close-orders-${alertSource}.mp4`} />
    );
  }, [segment, alertSource]);

  const getSegments = useCallback(() => {
    return {
      selected: segment,
      options: [{
        label: 'Preset',
        value: 'preset',
      }, {
        label: 'Alert',
        value: 'alert',
      }],
      change: changeSegmentHandler,
    };
  }, [segment, changeSegmentHandler]);

  useEffect(() => {
    if (!bot?.botUuid) {
      return;
    }

    dispatch(riskManagmentCloseActions.init(getCloseOrdersValue(bot)));
  }, [bot?.botUuid, dispatch]);

  return (
    <Stack sx={WRAPPER}>
      <Block
        title={(
          <BlockTitle
            title='Close orders'
            segment={getSegments()}
          />
        )}
        maxWidth={segment === 'alert' ? 400 : 0}
      >
        {getContent()}
      </Block>

      {getBlock()}
    </Stack>
  );
};

export default CloseOrders;
