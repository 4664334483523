import { secretToken, sourceUuid } from 'shared/consts';
import { generateCustomAlertSendRequest, generateIndicatorAlertMessage, generateStrategyEntryFunction } from 'shared/helpers';

interface IGetAdditionalCopyValue {
  source: 'indicator' | 'strategy' | 'custom';
  exchangeAccountUuid: string;
  position: number;
}
export const getAdditionalCopyValue = (params: IGetAdditionalCopyValue) => {
  const {
    source,
    exchangeAccountUuid,
    position,
  } = params;

  switch (source) {
  case 'indicator':
    return generateIndicatorAlertMessage(sourceUuid, secretToken(exchangeAccountUuid), position);
  case 'strategy':
    return generateStrategyEntryFunction(sourceUuid, secretToken(exchangeAccountUuid), position);
  case 'custom':
    return generateCustomAlertSendRequest(sourceUuid, secretToken(exchangeAccountUuid), position);
  default:
    return null;
  }
};
