import { SxProps, Theme } from '@mui/material';

export const FIELDS: SxProps<Theme> = {
  gap: '28px',
  width: '100%',
  maxWidth: {
    xs: '100%',
    md: 400,
  },
};
