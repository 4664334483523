import { Stack } from '@mui/material';
import { AdditionalEntry } from '../../../../additional-entry';
import { CloseOrders } from '../../../../close-orders';
import { WRAPPER } from '../styles';

export const Custom = () => {
  return (
    <Stack sx={WRAPPER}>
      <AdditionalEntry />
      <CloseOrders />
    </Stack>
  );
};
