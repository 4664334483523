import { Stack } from '@mui/material';
import { TradingViewLink } from 'pages/trading-bots/configurator-v2/components';
import { Copy, Label } from 'widgets';
import { generateStrategyEntryFunction } from 'shared/helpers';
import { Text } from 'shared/ui';
import { ITabProps } from '../../../interfaces';
import { WRAPPER } from '../styles';

export const Strategy = (props: ITabProps) => {
  return (
    <Stack sx={WRAPPER}>
      <Copy
        label={(
          <Label
            title='Entry function'
            desciption={(
              <Text>
                Add the parameter to your strategy entry function on {' '}

                <TradingViewLink />
              </Text>
            )}
          />
        )}
        value={generateStrategyEntryFunction(props.sourceUuid, props.secretToken, props.position)}
        display='row'
      />
    </Stack>
  );
};
