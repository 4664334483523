import { SxProps, Theme } from '@mui/material';

export const INNER: SxProps<Theme> = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: {
    xs: '12px',
    md: '24px',
  },
};
