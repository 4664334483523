import { SxProps, Theme } from '@mui/material';

export const LABEL: SxProps<Theme> = {
  padding: '4px 10px',
  backgroundColor: '#F2F2F2',
  borderRadius: '2.5px',
  fontSize: 12,
  fontWeight: 400,
  lineHeight: 'normal',
  color: '#3A3A3C',
};
