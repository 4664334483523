import { SxProps, Theme } from '@mui/material';

export const SETTINGS: SxProps<Theme> = {
  borderRadius: '6px',
  border: '0.96px solid rgba(144, 187, 176, 0.88)',
  background: 'linear-gradient(175deg, rgba(255, 255, 255, 0.17) 3%, rgba(165, 254, 227, 0.17) 131.88%)',
  boxShadow: '-2.161px -2.401px 2.545px 0px rgba(255, 255, 255, 0.32), 2.161px 2.401px 2.545px 0px rgba(57, 90, 74, 0.18)',
  padding: '14px',
  gap: '14px',
  flex: 1,
  maxWidth: 100,
};
