export const BUSD = (
  <svg width='120' height='120' viewBox='0 0 120 120' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_9496_20654)'>
      <circle cx='60' cy='60' r='60' fill='#EDF0F4'/>
      <path d='M59.8223 20L69.6885 30.1039L44.8447 54.9478L34.9785 45.0816L59.8223 20Z' fill='#F0B90B' stroke='#F0B90B'/>
      <path d='M74.7999 34.9785L84.6661 45.0825L44.8447 84.9039L34.9785 75.0377L74.7999 34.9785Z' fill='#F0B90B' stroke='#F0B90B'/>
      <path d='M29.8662 49.9551L39.7324 60.059L29.8662 69.9252L20 60.059L29.8662 49.9551Z' fill='#F0B90B' stroke='#F0B90B'/>
      <path d='M89.7765 49.9551L99.6427 60.059L59.8213 99.8805L49.9551 90.0142L89.7765 49.9551Z' fill='#F0B90B' stroke='#F0B90B'/>
    </g>
    <defs>
      <clipPath id='clip0_9496_20654'>
        <rect width='120' height='120' fill='white'/>
      </clipPath>
    </defs>
  </svg>
);
