import { Stack } from '@mui/material';
import { getExchange, getMarket, getTradingAmount } from 'entities/choose-account';
import { getTradingAmountError } from 'pages/trading-bots/configurator-v2/helpers';
import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { BasicInfo, Pairs, StepBlock } from '../../../components';
import { ActiveTradingPairs, Exchange, Market, TradingAmount } from '../components';
import { FIELDS, ROW, WRAPPER } from '../styles';

export const ChooseAccount = memo(() => {
  console.log('1', 1);
  const exchange = useSelector(getExchange);
  const tradingAmount = useSelector(getTradingAmount);
  const market = useSelector(getMarket);

  const tradingAmountError = getTradingAmountError({
    amount: +tradingAmount,
    minNotional: market.minNotional,
  });

  const getContent = useCallback(() => {
    if (!tradingAmount || tradingAmountError) {
      return null;
    }

    return (
      <Stack sx={ROW}>
        <Stack sx={FIELDS}>
          <ActiveTradingPairs />
        </Stack>

        <Pairs />
      </Stack>
    );
  }, [tradingAmount, tradingAmountError]);

  return (
    <StepBlock title='Step 1 - Choose account'>
      <Stack sx={WRAPPER}>
        <Stack sx={ROW}>
          <Stack sx={FIELDS}>
            <Exchange exchange={exchange} />

            <Market exchange={exchange} />

            <TradingAmount />
          </Stack>

          <BasicInfo
            title='Turn alerts from strategies and indicators into automated trades at your exchanges with flexible DCA, Scalping and GRID bots'
            links={[{
              title: 'How to connect alerts',
              type: 'router-link',
              url: '/trading-bots/introduction',
            }, {
              title: 'Ready-to-use scripts',
              type: 'link',
              url: 'https://www.tradingview.com/u/Skyrexio/#published-scripts',
            }]}
          />
        </Stack>

        {getContent()}
      </Stack>
    </StepBlock>
  );
});

ChooseAccount.displayName = 'ChooseAccount';
