import { Player } from '../components';

interface IGetPanelContent {
  isAlert: boolean;
  source: string;
  url?: string;
}

export const getPanelContent = (params: IGetPanelContent) => {
  const {
    isAlert,
    source,
    url,
  } = params;

  if (!isAlert) {
    return null;
  }

  const resultUrl = url || '';

  switch (source) {
  case 'indicator':
    return (
      <Player url={resultUrl} />
    );
  case 'strategy':
    return (
      <Player url={resultUrl} />
    );
  case 'custom':
    return (
      <Player url={resultUrl} />
    );
  default:
    return null;
  }
};
