import { Stack } from '@mui/material';
import { getAlertOrders, getAlertOrdersSizeScale, getAlertOrderType, riskManagmentAdditionalActions } from 'entities/risk-managment-additional';
import { IRiskManagmentAdditional } from 'entities/risk-managment-additional/interfaces';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { Label } from 'widgets';
import { CustomSlider, OrderType } from '../../../../../components';
import { INNER, WRAPPER } from '../styles';

export const Alerts = () => {
  const dispatch = useDispatch();

  const orders = useSelector(getAlertOrders);
  const ordersSizeScale = useSelector(getAlertOrdersSizeScale);
  const orderType = useSelector(getAlertOrderType);

  const [ordersValue, setOrdersValue] = useState(orders);
  const [ordersSizeScaleValue, setOrdersSizeScaleValue] = useState(ordersSizeScale);

  const debouncedValue = useDebouncedCallback((action: any, value: number) => {
    dispatch(action(value));
  }, 300);

  const changeAlertOrdersHandler = (value: number) => {
    setOrdersValue(value);
    debouncedValue(riskManagmentAdditionalActions.setAlertOrders, value);
  };

  const changeAlertOrdersSizeScaleHandler = (value: number) => {
    setOrdersSizeScaleValue(value);
    debouncedValue(riskManagmentAdditionalActions.setAlertOrdersSizeScale, value);
  };

  const changeAlertOrderTypeHandler = (value: string) => {
    dispatch(riskManagmentAdditionalActions.setAlertOrderType(value as IRiskManagmentAdditional['segment']['alert']['orderType']));
  };

  const isOneOrder = orders === 1;

  return (
    <Stack sx={INNER}>
      <Stack sx={WRAPPER}>
        <CustomSlider
          title={(
            <Label
              title='Orders'
            />
          )}
          slider={{
            value: ordersValue,
            max: 50,
            marks: {
              1: 1,
              5: 5,
              50: 50,
            },
            onChange: changeAlertOrdersHandler,
          }}
          input={{
            value: ordersValue,
            placeholder: '100%',
            onChange: changeAlertOrdersHandler,
          }}
        />

        <CustomSlider
          title={(
            <Label
              title='Orders size scale'
            />
          )}
          slider={{
            value: ordersSizeScaleValue,
            max: 5,
            marks: {
              1: 1,
              5: 5,
            },
            onChange: changeAlertOrdersSizeScaleHandler,
          }}
          input={{
            value: ordersSizeScaleValue,
            placeholder: '100%',
            onChange: changeAlertOrdersSizeScaleHandler,
          }}
          step={0.1}
          disabled={isOneOrder}
        />
        
        <OrderType
          value={orderType}
          onChange={changeAlertOrderTypeHandler}
        />
      </Stack>
    </Stack>
  );
};
