export const getUniqueSymbolCodes = (allExchangeSymbols: any) => {
  const result = allExchangeSymbols.reduce((uniqueSymbols: any, symbol: any) => {
    if (!uniqueSymbols.some((s: any) => s.quoteAsset === symbol.quoteAsset)) {
      uniqueSymbols.push(symbol);
    }
    return uniqueSymbols;
  }, []);

  return result;
};
