import { SxProps, Theme } from '@mui/material';

export const WRAPPER: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '40px',
  flex: 1,
  width: '100%',
  maxWidth: {
    xs: '100%',
    md: 747,
  },
  flexDirection: {
    xs: 'column',
    lg: 'row',
  },
};
