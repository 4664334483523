interface NelderMeadParameters {
  maxIterations?: number; // Maximum number of iterations for optimization
  nonZeroDelta?: number; // Perturbation factor for non-zero initial parameters
  zeroDelta?: number; // Perturbation factor for zero initial parameters
  minErrorDelta?: number; // Minimum acceptable difference in function values for convergence
  minTolerance?: number; // Minimum acceptable difference in parameter values for convergence
  rho?: number; // Reflection coefficient
  chi?: number; // Expansion coefficient
  psi?: number; // Contraction coefficient
  sigma?: number; // Reduction coefficient
  history?: Array<{
      x: number[]; // Current best parameters
      fx: number; // Current best function value
      simplex: SimplexPoint[]; // The entire simplex at the iteration
  }>; // Optional: Tracks optimization progress over iterations
  verbose?: boolean; // Enable verbose logging
}

interface NelderMeadResult {
  fx: number; // Minimum value of the function
  x: number[]; // Parameters that minimize the function
  iterations: number; // Total iterations performed
}

interface SimplexPoint extends Array<number> {
  fx: number; // Function value at this point
  id: number; // Unique identifier for the point
}

/**
* Computes a weighted sum of two vectors.
*
* @param ret The vector to store the result in.
* @param w1 The weight for the first vector.
* @param v1 The first vector.
* @param w2 The weight for the second vector.
* @param v2 The second vector.
*/
function weightedSum(
  ret: number[],
  w1: number,
  v1: number[],
  w2: number,
  v2: number[],
): void {
  for (let j = 0; j < ret.length; ++j) {
    ret[j] = w1 * v1[j] + w2 * v2[j];
  }
}

/**
* Minimizes a function using the Nelder-Mead optimization method.
*
* @param f The function to minimize
* @param x0 The initial guess for the parameters
* @param parameters Optional settings for the optimization process
* @returns The optimized parameters, the minimum function value, and the number of iterations
*/
export function nelderMead(
  f: (x: number[]) => number,
  x0: number[],
  parameters: NelderMeadParameters = {},
): NelderMeadResult {
  const maxIterations = parameters.maxIterations || x0.length * 200;
  const nonZeroDelta = parameters.nonZeroDelta || 0.05;
  const zeroDelta = parameters.zeroDelta || 0.00025;
  const minErrorDelta = parameters.minErrorDelta || 1e-6;
  const minTolerance = parameters.minTolerance || 1e-5;
  const rho = parameters.rho !== undefined ? parameters.rho : 1;
  const chi = parameters.chi !== undefined ? parameters.chi : 2;
  const psi = parameters.psi !== undefined ? parameters.psi : 0.5;
  const sigma = parameters.sigma !== undefined ? parameters.sigma : 0.5;
  const verbose = parameters.verbose || false;

  const N = x0.length;
  const simplex: SimplexPoint[] = new Array(N + 1);

  // Initialize simplex
  simplex[0] = [...x0] as SimplexPoint;
  simplex[0].fx = f(x0);
  simplex[0].id = 0;

  for (let i = 0; i < N; ++i) {
    const point = [...x0];
    point[i] = point[i] !== 0 ? (1 + nonZeroDelta) * point[i] : zeroDelta;
    simplex[i + 1] = point as SimplexPoint;
    simplex[i + 1].fx = f(point);
    simplex[i + 1].id = i + 1;
  }

  const centroid = new Array(N).fill(0);
  const reflected = new Array(N) as SimplexPoint;
  const expanded = new Array(N) as SimplexPoint;
  const contracted = new Array(N) as SimplexPoint;

  let iterations = 0;

  while (iterations < maxIterations) {
    simplex.sort((a, b) => a.fx - b.fx);

    const bestFx = simplex[0].fx;
    const worstFx = simplex[N].fx;

    if (
      Math.abs(worstFx - bestFx) < minErrorDelta &&
          simplex.reduce((maxDiff, point, idx) => {
            if (idx === 0) return maxDiff;
            const diff = point.reduce(
              (sum, val, i) => sum + Math.abs(val - simplex[0][i]),
              0,
            );
            return Math.max(maxDiff, diff);
          }, 0) < minTolerance
    ) {
      break; // Converged
    }

    // Compute centroid of the best N points (excluding worst)
    for (let i = 0; i < N; ++i) {
      centroid[i] = 0;
      for (let j = 0; j < N; ++j) {
        centroid[i] += simplex[j][i];
      }
      centroid[i] /= N;
    }

    const worstPoint = simplex[N];

    // Reflection
    weightedSum(reflected, 1 + rho, centroid, -rho, worstPoint);
    reflected.fx = f(reflected);

    if (reflected.fx < simplex[0].fx) {
      // Expansion
      weightedSum(expanded, 1 + rho * chi, centroid, -rho * chi, worstPoint);
      expanded.fx = f(expanded);
      if (expanded.fx < reflected.fx) {
        simplex[N] = [...expanded] as SimplexPoint;
        simplex[N].fx = expanded.fx;
      } else {
        simplex[N] = [...reflected] as SimplexPoint;
        simplex[N].fx = reflected.fx;
      }
    } else if (reflected.fx < simplex[N - 1].fx) {
      simplex[N] = [...reflected] as SimplexPoint;
      simplex[N].fx = reflected.fx;
    } else {
      // Contraction
      if (reflected.fx < worstPoint.fx) {
        // Outside contraction
        weightedSum(
          contracted,
          1 + psi * rho,
          centroid,
          -psi * rho,
          worstPoint,
        );
        contracted.fx = f(contracted);
        if (contracted.fx <= reflected.fx) {
          simplex[N] = [...contracted] as SimplexPoint;
          simplex[N].fx = contracted.fx;
        } else {
          // Reduction
          for (let i = 1; i <= N; i++) {
            weightedSum(simplex[i], 1 - sigma, simplex[0], sigma, simplex[i]);
            simplex[i].fx = f(simplex[i]);
          }
        }
      } else {
        // Inside contraction
        weightedSum(contracted, 1 - psi, centroid, psi, worstPoint);
        contracted.fx = f(contracted);
        if (contracted.fx <= worstPoint.fx) {
          simplex[N] = [...contracted] as SimplexPoint;
          simplex[N].fx = contracted.fx;
        } else {
          // Reduction
          for (let i = 1; i <= N; i++) {
            weightedSum(simplex[i], 1 - sigma, simplex[0], sigma, simplex[i]);
            simplex[i].fx = f(simplex[i]);
          }
        }
      }
    }

    if (parameters.history) {
      const simplexCopy = simplex.map((point) => {
        const copy = [...point] as SimplexPoint;
        copy.fx = point.fx;
        copy.id = point.id;
        return copy;
      });
      parameters.history.push({
        x: [...simplex[0]],
        fx: simplex[0].fx,
        simplex: simplexCopy,
      });
    }

    if (verbose) {
      console.log(`Iteration ${iterations}: Best fx = ${simplex[0].fx}`);
    }

    iterations++;
  }

  simplex.sort((a, b) => a.fx - b.fx);

  return {
    fx: simplex[0].fx,
    x: simplex[0],
    iterations,
  };
}
