import { botActions } from 'entities/bot/model/slices/bot-slice';
import { chooseAccountActions } from 'entities/choose-account';
import { ExchangeAccount } from 'entities/exchange/model/types/exchange-account';
import { getIsDemoMode } from 'entities/user/model/selectors/get-is-demo-mode/get-is-demo-mode';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getExchangesIds } from 'widgets/exchanges/api/get-exchanges';
import { getDemoAccount } from 'shared/helpers';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';
import { setExchanges } from '../actions';
import { getBotInfo } from '../api';
import { convertExchange } from '../helpers';

export const useGetExchanges = (id?: string) => {
  const isDemoMode = useSelector(getIsDemoMode);
  const dispatch = useDispatch();

  const getAccountsHandler = async () => {
    try {
      const userId = getSkyrexUuid();

      const {
        data,
      } = await getExchangesIds(userId as string);

      const responseAccounts = data?.accounts;
      if (!responseAccounts?.length) {
        dispatch(setExchanges([]));
        return;
      }

      if (id) {
        const botInfoResponse = await getBotInfo({
          botUuid: id,
        });
  
        if (!botInfoResponse?.length) {
          return;
        }
  
        const [bot] = botInfoResponse;
        dispatch(botActions.setCurrentBot(bot));
  
        const exchangeAccount = responseAccounts.find((account: ExchangeAccount) => account.exchangeAccountUuid === bot.exchangeAccountUuid);
        const convertedExchange = convertExchange(exchangeAccount);
        
        dispatch(chooseAccountActions.setExchanges([exchangeAccount]));
        dispatch(chooseAccountActions.selectExchange(convertedExchange));
        return;
      }

      const resultAccounts = getDemoAccount(responseAccounts, isDemoMode);
      // dispatch(setExchanges(resultAccounts?.length ? resultAccounts : []));
      dispatch(chooseAccountActions.setExchanges(resultAccounts?.length ? resultAccounts : []));
      
      const exchange = resultAccounts?.[0];
      // dispatch(selectExchange(exchange?.exchangeAccountUuid));
      dispatch(chooseAccountActions.selectExchange(convertExchange(exchange)));
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    getAccountsHandler();
  }, [isDemoMode, id]);
};
