import { BNB, BTC, DAI, DOGE, ETH, TRX, USDC, USDT, XRP } from 'shared/icons/market';

export interface IMarketIcons {
  usdt: JSX.Element;
  usdc: JSX.Element;
  bnb: JSX.Element;
  eth: JSX.Element;
  btc: JSX.Element;
  xrp: JSX.Element;
  trx: JSX.Element;
  doge: JSX.Element;
  dai: JSX.Element;
}

export const marketIcons: IMarketIcons = {
  usdt: USDT,
  usdc: USDC,
  bnb: BNB,
  eth: ETH,
  btc: BTC,
  xrp: XRP,
  trx: TRX,
  doge: DOGE,
  dai: DAI,
};
