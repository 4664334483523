import { ICopy } from 'widgets/copy/interfaces';
import { preCode } from '../styles/pre-code';

export const renderJson = (data: any, display: ICopy['display'] = 'json') => {
  let row = '';

  const isString = typeof data === 'string';
  if (isString) {
    row = data;
  } else {
    row = JSON.stringify(data, null, 2);
  }

  let styles = preCode;

  if (display === 'json') {
    styles = {
      ...styles,
      width: '100%',
      overflow: 'hidden',
    };
  }

  if (display === 'row') {
    styles = {
      ...styles,
      whiteSpace: 'nowrap',
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    };
  }

  return (
    <pre style={styles}>
      {row}
    </pre>
  );
};
