import { Box, Stack } from '@mui/material';
import { coins } from 'shared/consts';
import { Link, Title } from 'shared/ui';
import { COIN, INNER, LINK, TITLE, WRAPPER } from '../styles';

export const Pairs = () => {
  return (
    <Stack sx={WRAPPER}>
      <Title styles={TITLE}>
        Bot can manage multiple pairs — set alerts on recommended coins or pick from the {' '}
        <Link type='success' styles={LINK}>Screener</Link> and <Link type='success' styles={LINK}>Heatmap</Link>
      </Title>

      <Stack sx={INNER}>
        {coins.map((coin, index) => (
          <Box
            key={index}
            sx={COIN}
          >
            {coin}
          </Box>
        ))}
      </Stack>
    </Stack>
  );
};
