import { Stack } from '@mui/material';
import { getAlertSource } from 'entities/choose-source';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { Copy, Label } from 'widgets';
import { Link, Text } from 'shared/ui';
import { Player, StepBlock } from '../../../components';
import { INNER, WRAPPER } from '../styles';

export const CreateAlertMessage = memo(() => {
  const alertSource = useSelector(getAlertSource);
  
  return (
    <Stack sx={WRAPPER}>
      <Stack
        sx={{
          flex: 1,
          width: '100%',
          maxWidth: 400,
        }}
      >
        <StepBlock title='Step 4 - Create alert message'>
          <Stack sx={INNER}>
            <Copy
              label={(
                <Label
                  title='Webhook URL'
                  desciption={(
                    <Text>
                      Copy and paste this URL into your Alert notifications tab on <Link type='success'>TradingView</Link>
                    </Text>
                  )}
                />
              )}
              value='https://panel.skyrexio.com/api/v1/deal/alert'
            />

            <Copy
              label={(
                <Label
                  title='Alert with the message'
                  desciption={(
                    <Text>
                      Copy and paste this alert message into your Alert on <Link type='success'>TradingView</Link>
                    </Text>
                  )}
                />
              )}
              value='{{strategy.order.alert_message}}'
            />
          </Stack>
        </StepBlock>
      </Stack>

      <Player url={`/videos/${alertSource}.mp4`} />
    </Stack>
  );
});

CreateAlertMessage.displayName = 'CreateAlertMessage';
