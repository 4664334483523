import sign from 'jwt-encode';

interface IGenerateSecretToken {
  exchangeAccountUuid: string;
  skyrexUuid: string;
  sourceUuid: string;
}
export const generateSecretToken = (params: IGenerateSecretToken) => {
  return sign(params, process.env.REACT_APP_SECRET_TOKEN_PRIVATE_KEY || Math.random().toString(), {
    algorithm: 'RS256',
  });
};
