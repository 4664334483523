import { Stack } from '@mui/material';
import { getCurrentBot } from 'entities/bot/model/selectors/get-current-bot/get-current-bot';
import { chooseAccountActions, getMarket } from 'entities/choose-account';
import { getTradingAmountError } from 'pages/trading-bots/configurator-v2/helpers';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { Label } from 'widgets';
import { LabelInput, Text } from 'shared/ui';
import { TRADING_AMOUNT_INPUT } from '../../../styles';

export const TradingAmount = () => {
  const bot = useSelector(getCurrentBot);
  const market = useSelector(getMarket);

  const [amount, setAmount] = useState<string>('');

  const dispatch = useDispatch();

  const debounceAmount = useDebouncedCallback((value: string) => {
    dispatch(chooseAccountActions.setTradingAmount(value));
  }, 300);

  const setTradingAmountHandler = (value: string) => {
    setAmount(value);
    debounceAmount(value);
    // if (INPUT_POSITIVE_NUBER.test(value) || value === '') {
    //   // setTradingAmount(value);
    // }
  };

  const error = getTradingAmountError({
    amount: +amount,
    minNotional: market.minNotional,
  });

  useEffect(() => {
    if (!bot?.botUuid) {
      return;
    }

    dispatch(chooseAccountActions.setTradingAmount(bot.tradingAmount.toString()));
    setAmount(bot?.tradingAmount?.toString() || '');
  }, [bot, dispatch]);

  return (
    <Stack
      gap='8px'
      height={98}
    >
      <LabelInput
        label={(
          <Label
            title='Trading amount'
          />
        )}
        value={amount}
        placeholder='Set amount to continue'
        onChange={setTradingAmountHandler}
        style={TRADING_AMOUNT_INPUT}
      />

      <Text type='danger'>
        {error}
      </Text>
    </Stack>
  );
};
