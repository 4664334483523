import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRiskManagmentAuto } from '../interfaces';

const initialState: IRiskManagmentAuto = {
  segment: 'auto',
  preset: 'conservative',
  values: {
    additionalOrderPriceChange: 0,
    additionalOrderPriceChangeScale: 0,
    additionalOrderSizeScale: 0,
    maxAdditionalOrders: 0,
    stopLossPercent: 0,
    taketProfitPercent: 0,
    baseOrderSize: 0,
  },
};

export const riskManagmentAutoSlice = createSlice({
  name: 'risk-managment-auto',
  initialState,
  reducers: {
    init: (state, action: PayloadAction<IRiskManagmentAuto>) => {
      const {
        segment,
        preset,
        values,
      } = action.payload;

      state.segment = segment;
      state.preset = preset;
      state.values = values;
    },
    selectSegment: (state, action: PayloadAction<IRiskManagmentAuto['segment']>) => {
      state.segment = action.payload;
    },
    setError: (state, action: PayloadAction<IRiskManagmentAuto['error']>) => {
      state.error = action.payload;
    },
    selectPreset: (state, action: PayloadAction<IRiskManagmentAuto['preset']>) => {
      state.preset = action.payload;
    },
    setValues: (state, action: PayloadAction<IRiskManagmentAuto['values']>) => {
      state.values = action.payload;
    },
  },
});

export const {
  actions: riskManagmentAutoActions,
} = riskManagmentAutoSlice;
export const {
  reducer: riskManagmentAutoReducer,
} = riskManagmentAutoSlice;
