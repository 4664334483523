import { IPresetProps } from '../views/configure-risk-managment/components/preset/interfaces';

export const presets: IPresetProps[] = [{
  title: 'Conservative',
  settings: {
    display: [{
      title: 'Overlap',
      value: '-30%',
    }, {
      title: 'Take profit',
      value: '4%',
    }, {
      title: 'Stop loss',
      value: 'Disabled',
    }],
    values: {
      overlap: -30,
      initialAdditionalOrderSizeScale: 1.8,
      initialAdditionalOrderPriceChange: -3,
      initialAdditionalOrderPriceChangeScale: 1.6,
      initialMaxAdditionalOrders: 5,
      stopLossPercent: 0,
      takeProfitPercent: 4,
    },
  },
  caption: 'Dollar-Cost Averaging with widening entries',
  description: 'Larger buys at lower prices, perfect for low-risk position building in volatile markets',
}, {
  title: 'Aggressive',
  settings: {
    display: [{
      title: 'Overlap',
      value: 'Disabled',
    }, {
      title: 'Take profit',
      value: '5%',
    }, {
      title: 'Stop loss',
      value: '-2%',
    }],
    values: {
      overlap: 0,
      initialAdditionalOrderSizeScale: 0,
      initialAdditionalOrderPriceChange: 0,
      initialAdditionalOrderPriceChangeScale: 0,
      initialMaxAdditionalOrders: 0,
      stopLossPercent: -2,
      takeProfitPercent: 5,
    },
  },
  caption: 'Momentum trading with high reward-risk ratio',
  description: 'Target strong uptrends with wide stop loss and take profit for maximum gains',
}, {
  title: 'Moderate',
  settings: {
    display: [{
      title: 'Overlap',
      value: '-10%',
    }, {
      title: 'Take profit',
      value: '6%',
    }, {
      title: 'Stop loss',
      value: '-4%',
    }],
    values: {
      overlap: -10,
      initialAdditionalOrderSizeScale: 1.75,
      initialAdditionalOrderPriceChange: -2.5,
      initialAdditionalOrderPriceChangeScale: 1.45,
      initialMaxAdditionalOrders: 3,
      stopLossPercent: -4,
      takeProfitPercent: 6,
    },
  },
  caption: 'Swing trading with distributed entries',
  description: 'Capture market swings in sideways or moderate uptrends with natural corrections',
}];
