import { getCurrentBot } from 'entities/bot/model/selectors/get-current-bot/get-current-bot';
import { chooseAccountActions } from 'entities/choose-account';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { EmptySubscription, Input } from 'shared/ui';
import { IBotNameProps } from '../interfaces';

export const BotName = (props: IBotNameProps) => {
  const {
    isModalOpened,
    handleNameModalState,
    createBotHandler,
  } = props;

  const bot = useSelector(getCurrentBot);
  const [name, setName] = useState<string>(bot?.name || '');

  const dispatch = useDispatch();
  
  const debouncedValue = useDebouncedCallback((value: string) => {
    dispatch(chooseAccountActions.setName(value));
  }, 300);
  
  const handleClose = () => {
    handleNameModalState(false);
  };

  const onChangeHandler = (value: string) => {
    setName(value);
    debouncedValue(value);
  };

  return (
    <EmptySubscription
      isOpen={isModalOpened}
      modalTitle='Name your bot'
      handleClose={handleClose}
      button={{
        title: 'Create',
        action: createBotHandler,
        disabled: !name,
      }}
    >
      <Input
        value={name}
        placeholder='Set any name to continue'
        onChange={onChangeHandler}
      />
    </EmptySubscription>
  );
};
