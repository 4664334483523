import { SxProps, Theme } from '@mui/material';

export const ROW: SxProps<Theme> = {
  // flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '24px',
  width: '100%',
  flexDirection: {
    xs: 'column',
    md: 'row',
  },
};
