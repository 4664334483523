import { Box, Stack } from '@mui/material';
import { IMarketIcons, marketIcons } from 'shared/consts/market-icon';
import { Text, Title } from 'shared/ui';
import { IMarketProps } from '../interfaces';
import { DEFAULT_ICON, INNER, TITLE, VALUE, WRAPPER } from '../styles';

export const Market = (props: IMarketProps) => {
  const getValue = () => {
    const [value, pair] = props.value.split(' ');

    return (
      <Stack sx={VALUE}>
        <Text>{value}</Text>
        <Text>{pair}</Text>
      </Stack>
    );
  };

  const getIcon = () => {
    const code = props.code.toLowerCase();
    const icon = marketIcons[code as keyof IMarketIcons];

    if (!icon) {
      return (
        <Box sx={DEFAULT_ICON}>
          {code.slice(0, 2)}
        </Box>
      );
    }

    return icon;
  };
  
  return (
    <Stack sx={WRAPPER}>
      <Stack sx={INNER}>
        <Box>
          {getIcon()}
        </Box>

        <Title styles={TITLE}>
          {props.title}
        </Title>
      </Stack>

      {getValue()}
    </Stack>
  );
};
