import { Stack } from '@mui/material';
import { Segmented } from 'antd';
import { Switch, Title } from 'shared/ui';
import { IBlockTitleProps } from '../interfaces';
import { INNER, TITLE, WRAPPER } from '../styles';

export const BlockTitle = (props: IBlockTitleProps) => {
  const {
    title,
    toggle,
    segment,
  } = props;

  const getSegmnet = () => {
    if (!segment) {
      return null;
    }

    return (
      <Segmented
        value={segment.selected}
        options={segment.options}
        onChange={segment.change}
      />
    );
  };

  return (
    <Stack sx={WRAPPER}>
      <Stack sx={INNER}>
        <Title styles={TITLE}>
          {title}
        </Title>

        {toggle && (
          <Switch
            value={toggle.toggled}
            onChange={toggle.change}
          />
        )}
      </Stack>

      {getSegmnet()}
    </Stack>
  );
};
