import { SxProps, Theme } from '@mui/material';

export const PRESETS: SxProps<Theme> = {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 1fr))',
  alignItems: 'flex-start',
  gap: {
    xs: '24px',
    md: '44px',
  },
  flex: 1,
  width: '100%',
  // maxWidth: 817,
};
