import { SxProps, Theme } from '@mui/material';

export const WRAPPER: SxProps<Theme> = {
  borderRadius: '14px',
  backgroundColor: 'rgba(158, 158, 158, 0.05)',
  flex: 1,
  // maxWidth: 385,
  padding: '34px',
  alignItems: 'initial',
  gap: '24px',
};
