import { currencyFormatter } from 'shared/helpers/currency-formatter';

export const calculateMarketOptions = (userWallet: any, uniqueSymbolCodes: any) => {
  // Создаем массив объектов, содержащих информацию о каждом токене и его балансе
  const options = uniqueSymbolCodes.map((token: any) => {
    const walletBalance = userWallet?.find((el: any) => el.symbol === token.quoteAsset)?.free || '0';
    return {
      label: `${token.quoteAsset}_${currencyFormatter(walletBalance, token.chartPrecision)}`,
      value: String(token.quoteAsset).toUpperCase(),
      balance: walletBalance,
      filter: +token?.minNotional,
      chartPrecision: token?.chartPrecision,
    };
  });

  // Сортируем массив по убыванию баланса
  const sorted = options.sort((a: any, b: any) => b.balance - a.balance);

  return sorted;
};
