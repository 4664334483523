import { getAdditionalEntries } from './get-additional-entries';
import { getBaseOrder } from './get-base-order';

interface IGetFullPosition {
  additional: {
    isOpened: boolean;
    ordersSizeScale: number;
    priceChange: number;
    ordersPriceChangeScale: number;
    orders: number;
    segment: string;
  };
  chooseAccount: {
    tradingAmount: number;
    activeTradingPairs: number;
  };
}
export const getFullPosition = (params: IGetFullPosition) => {
  const {
    additional,
    chooseAccount,
  } = params;

  if (!(chooseAccount.tradingAmount && chooseAccount.activeTradingPairs)) {
    return 0;
  }

  // const orderSizeSum = items.reduce((acc, next) => acc + next.orderSize, 0);
  const orderSizeSum = getAdditionalEntries({
    isOpened: additional.isOpened,
    segment: additional.segment,
    orders: additional.orders,
    priceChange: additional.priceChange,
    ordersSizeScale: additional.ordersSizeScale,
    ordersPriceChangeScale: additional.ordersPriceChangeScale,
    tradingAmount: chooseAccount.tradingAmount,
    activeTradingPairs: chooseAccount.activeTradingPairs,
  }).reduce((acc, next) => acc + next.orderSize, 0);

  const baseOrder = getBaseOrder({
    additional,
    chooseAccount,
  });
  
  return baseOrder  + orderSizeSum;
};
