import { ExchangeAccount } from 'entities/exchange/model/types/exchange-account';
import { getExchangeType } from './get-exchange-type';

export const convertExchange = (exchange: ExchangeAccount) => {
  return {
    id: exchange?.exchangeAccountUuid || '',
    name: exchange?.accountName || '',
    code: exchange?.exchangeCode || '',
    type: getExchangeType(exchange?.exchangeCode || ''),
  };
};
