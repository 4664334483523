import { chooseAccountActions, getMarket } from 'entities/choose-account';
import { IChooseAccount } from 'entities/choose-account/interfaces';
import { BotData } from 'pages/trading-bots/my-bot/ui/my-bot';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getExchanges } from 'widgets/exchanges/api/get-exchanges';
import { exchangeInfoRequests } from 'shared/consts';
import { getUniqueSymbolCodes } from 'shared/helpers';
import { calculateMarketOptions, convertMarket } from '../helpers';

interface IUseGetMarkets {
  exchange: IChooseAccount['exchange'];
  bot?: BotData;
}
export const useGetMarkets = (params: IUseGetMarkets) => {
  const {
    exchange,
    bot,
  } = params;

  const [loading, setLoading] = useState<boolean>(true);
  const [symbols, setSymbols] = useState<any[]>([]);

  const dispatch = useDispatch();

  const selectedMarket = useSelector(getMarket);

  const getMarketsHandler = useCallback(async () => {
    setLoading(true);
    try {
      const {
        data,
      } = await getExchanges([exchange.id]);

      const [chosenExchange] = data?.accounts;
      if (!chosenExchange) {
        return;
      }

      const request = exchangeInfoRequests[exchange.code.toLowerCase()];
      const response = await request();
      if (!response) {
        return;
      }

      setSymbols(response);

      const list = getUniqueSymbolCodes(response);
      const markets = calculateMarketOptions(chosenExchange.balances, list);
      if (!markets?.length) {
        return;
      }

      dispatch(chooseAccountActions.setMarkets(markets));

      const market = markets.find((market: any) => {
        if (bot?.quote) {
          return market.value === bot.quote;
        }
  
        return market.value === 'USDT';
      });

      dispatch(chooseAccountActions.selectMarket(convertMarket(market || markets[0])));
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  }, [exchange, dispatch, bot?.quote]);

  useEffect(() => {
    if (!symbols.length) {
      return;
    }

    const filteredMarketSymbols = symbols.filter((symbol) => symbol.quoteAsset === selectedMarket.code);
    const minNotional = filteredMarketSymbols.reduce((prev, current) => {
      if (+prev < +current.minNotional) {
        prev = +current.minNotional;
        return prev;
      }

      return prev;
    }, 0);

    dispatch(chooseAccountActions.setMinNotional(minNotional));
  }, [selectedMarket.code, symbols, dispatch]);

  useEffect(() => {
    if (!exchange?.id) {
      return;
    }

    getMarketsHandler();
  }, [exchange.id, getMarketsHandler, bot?.botUuid]);

  return {
    loading,
  };
};
