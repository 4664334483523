import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IChooseSource } from '../interfaces';

const initialState: IChooseSource = {
  alertSource: 'indicator',
  orderType: 'market',
};

export const chooseSourceSlice = createSlice({
  name: 'choose-source',
  initialState,
  reducers: {
    init: (state, action: PayloadAction<IChooseSource>) => {
      const {
        alertSource,
        orderType,
      } = action.payload;

      state.alertSource = alertSource;
      state.orderType = orderType;
    },
    selectAlertSource: (state, action: PayloadAction<IChooseSource['alertSource']>) => {
      state.alertSource = action.payload;
    },
    selectOrderType: (state, action: PayloadAction<IChooseSource['orderType']>) => {
      state.orderType = action.payload;
    },
  },
});

export const {
  actions: chooseSourceActions,
} = chooseSourceSlice;
export const {
  reducer: chooseSourceReducer,
} = chooseSourceSlice;
