import { getSegment, riskManagmentAutoActions } from 'entities/risk-managment-auto';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs } from 'widgets';
import { StepBlock } from '../../../components';
import { Auto, Custom } from '../tabs';

export const ConfigureRiskManagment = memo(() => {
  const dispatch = useDispatch();

  const segment = useSelector(getSegment);

  const changeSegmentHandler = (value: number) => {
    const segment = value ? 'custom' : 'auto';
    dispatch(riskManagmentAutoActions.selectSegment(segment));
  };
  
  return (
    <StepBlock title='Step 3 - Configure risk management'>
      <Tabs
        defaultValue={segment === 'auto' ? 0 : 1}
        tabs={[{
          label: 'Auto',
          value: 0,
        }, {
          label: 'Custom',
          value: 1,
        }]}
        content={[(
          <Auto />
        ), (
          <Custom />
        )]}
        styles={{
          alignItems: 'flex-start',
        }}
        maxWidth='265px'
        segmentStyles={{
          alignSelf: 'flex-start',
        }}
        getTab={changeSegmentHandler}
      />
    </StepBlock>
  );
});

ConfigureRiskManagment.displayName = 'ConfigureRiskManagment';
