export const generateIndicatorAlertMessage = (sourceUuid?: string, secretToken?: string, position?: number | string) => {
  return {
    'base': '{{syminfo.basecurrency}}',
    'quote': '{{syminfo.currency}}',
    'position': typeof position === 'string' ? position : `entry${position}`,
    'price': '{{close}}',
    'sourceUuid': sourceUuid || '',
    'secretToken': secretToken || '',
    'timestamp': '{{timenow}}',
  };
};
