import { nelderMead } from './nelderMead';

/**
 * Calibrates entry order parameters for a trading strategy.
 *
 * Steps:
 * 1) Find scenarios that meet core constraints:
 *    - Each order ≥ minNotional
 *    - If stopLossPercent ≠ 0: stopLoss≥minNotional and stopLossPercent<0
 *    - |overlap - achievedOverlap| ≤ overlapDifferenceTolerance
 *    - If stopLossPercent ≠ 0, ensure stopLossPercent < WeightedDrawdownPercent
 *
 * 2) Two-stage approach if alpha>0:
 *    Stage 1 (alpha=0): Focus only on meeting constraints and minimizing diff.
 *                       Pick scenario with smallest diff.
 *                       If none found, fail.
 *
 *    Stage 2 (alpha=initialAlpha): Try to improve WeightedDrawdownPercent without increasing diff.
 *                                  If no improvement, return stage 1 scenario.
 *
 * If alpha=0 (no stage 2), just return the stage 1 scenario.
 *
 * 3) If globalSearchSamples>0, global pre-search is informational only.
 *
 * 4) If no scenario can meet constraints, return failure.
 */
interface ICalibrateEntryOrdersParameters {
  tradingAmount: number;
  activeTradingPairs: number;
  minNotional: number;
  overlap: number;
  initialAdditionalOrderSizeScale: number;
  initialAdditionalOrderPriceChange: number;
  initialAdditionalOrderPriceChangeScale: number;
  initialMaxAdditionalOrders: number;
  stopLossPercent: number;
  maxIterations: number;
  percentRange: number;
  iterationStep: number;
  overlapDifferenceTolerance: number;
  alpha: number;
  globalSearchSamples: number;
}

export function calibrateEntryOrdersParameters(params: ICalibrateEntryOrdersParameters): {
  message: string;
  result?: {
    calibratedAdditionalOrderSizeScale: number;
    calibratedAdditionalOrderPriceChange: number;
    calibratedAdditionalOrderPriceChangeScale: number;
    calibratedMaxAdditionalOrders: number;
    baseOrderSize: number;
    fullPositionSize: number;
    stopLossOrderSize: number | string;
    stopLossPercent: number;
    overlap: number;
    weightedPriceChangePercent: number;
    weightedDrawdownPercent: number;
    diff: number;
    iterations: number;
  };
  failureDetails?: string[];
  bestMatchingParameters?: {
    calibratedAdditionalOrderSizeScale: number;
    calibratedAdditionalOrderPriceChange: number;
    calibratedAdditionalOrderPriceChangeScale: number;
    calibratedMaxAdditionalOrders: number;
    baseOrderSize: number;
    fullPositionSize: number;
    stopLossOrderSize: number | string;
    stopLossPercent: number;
    overlap: number;
    weightedPriceChangePercent: number;
    weightedDrawdownPercent: number;
    diff: number;
    iterations: number;
  };
} {
  const {
    tradingAmount,
    activeTradingPairs,
    minNotional,
    overlap,
    initialAdditionalOrderSizeScale,
    initialAdditionalOrderPriceChange,
    initialAdditionalOrderPriceChangeScale,
    initialMaxAdditionalOrders,
    stopLossPercent,
    maxIterations,
    percentRange,
    iterationStep,
    overlapDifferenceTolerance,
    alpha,
    globalSearchSamples,
  } = params;
  
  const constrainToPercent = (value: number): [number, number] => {
    const delta = (Math.abs(value) * percentRange) / 100;
    return [value - delta, value + delta];
  };

  const [minSizeScale, maxSizeScale] = constrainToPercent(initialAdditionalOrderSizeScale);
  const [minPriceChange, maxPriceChange] = constrainToPercent(initialAdditionalOrderPriceChange);
  const [minPriceChangeScale, maxPriceChangeScale] = constrainToPercent(initialAdditionalOrderPriceChangeScale);

  const getBaseOrderSize = (orderSizeScale: number, maxOrders: number): number => {
    const tradingLimitPerPair = tradingAmount / activeTradingPairs;
    if (orderSizeScale === 1) {
      return tradingLimitPerPair / (maxOrders + 1);
    } else if (orderSizeScale === 0) {
      return tradingLimitPerPair;
    }
    const denominator = 1 - Math.pow(orderSizeScale, maxOrders + 1);
    if (denominator === 0) return tradingLimitPerPair;
    return (tradingLimitPerPair * (1 - orderSizeScale)) / denominator;
  };

  const getFullPositionSize = (baseOrderSize: number, orderSizeScale: number, maxOrders: number): number => {
    if (orderSizeScale === 1) {
      return baseOrderSize * (maxOrders + 1);
    } else if (orderSizeScale === 0) {
      return baseOrderSize;
    }
    const denominator = 1 - orderSizeScale;
    const numerator = 1 - Math.pow(orderSizeScale, maxOrders + 1);
    if (denominator === 0) return baseOrderSize * (maxOrders + 1);
    return baseOrderSize * (numerator / denominator);
  };

  const getCumulativeDifference = (i: number, priceChange: number, priceChangeScale: number): number => {
    if (i === 0) return 0;
    if (priceChangeScale === 1) {
      return priceChange * i;
    }
    const numerator = 1 - Math.pow(priceChangeScale, i);
    const denominator = 1 - priceChangeScale;
    return priceChange * (numerator / denominator);
  };

  const getWeightedPriceChangePercent = (
    baseOrderSize: number,
    maxOrders: number,
    priceChange: number,
    priceChangeScale: number,
    orderSizeScale: number,
  ): number => {
    let totalPositionSize = 0;
    for (let i = 0; i <= maxOrders; i++) {
      const sizeFactor = orderSizeScale === 0 && i === 0 ? 1 : Math.pow(orderSizeScale, i);
      totalPositionSize += baseOrderSize * sizeFactor;
    }

    let weightedSum = 0;
    for (let i = 0; i <= maxOrders; i++) {
      const sizeFactor = orderSizeScale === 0 && i === 0 ? 1 : Math.pow(orderSizeScale, i);
      const orderSize = baseOrderSize * sizeFactor;
      const cumulativeDiff = getCumulativeDifference(i, priceChange, priceChangeScale);
      weightedSum += orderSize * cumulativeDiff;
    }

    return weightedSum / totalPositionSize;
  };

  const computeOverlapAchieved = (maxOrders: number, priceChange: number, priceChangeScale: number): number => {
    if (maxOrders === 0) return overlap === 0 ? 0 : priceChange;
    if (priceChangeScale === 1) {
      return priceChange * maxOrders;
    }
    const numerator = 1 - Math.pow(priceChangeScale, maxOrders);
    const denominator = 1 - priceChangeScale;
    return priceChange * (numerator / denominator);
  };

  const validateStopLoss = (fullPositionSize: number, stopLossPercent: number, weightedDrawdown: number): boolean => {
    if (stopLossPercent === 0) return true;
    const stopLossOrderSize = fullPositionSize * (1 + stopLossPercent / 100);
    if (stopLossOrderSize < minNotional || stopLossPercent >= 0) return false;
    if (!Number.isFinite(weightedDrawdown)) return false;
    return stopLossPercent < weightedDrawdown;
  };

  const scenarioMeetsConstraints = (
    baseOrderSize: number,
    maxOrders: number,
    priceChange: number,
    priceChangeScale: number,
    orderSizeScale: number,
  ): { meets: boolean; diff: number; weightedDrawdown: number; failureDetails: string[]; } => {
    const failureDetails: string[] = [];
    const overlapAchieved = computeOverlapAchieved(maxOrders, priceChange, priceChangeScale);
    const diff = Math.abs(overlap - overlapAchieved);

    // Check order sizes
    let additionalOrdersFail = false;
    for (let i = 0; i <= maxOrders; i++) {
      const sizeFactor = orderSizeScale === 0 && i === 0 ? 1 : Math.pow(orderSizeScale, i);
      const orderSize = baseOrderSize * sizeFactor;
      if (orderSize < minNotional) {
        if (i === 0) {
          failureDetails.push('Base order is less than minimum order amount.');
        } else {
          additionalOrdersFail = true;
        }
      }
    }
    if (additionalOrdersFail) {
      failureDetails.push('Some additional orders are too small.');
    }

    // Check stop loss
    if (stopLossPercent !== 0) {
      const stopLossOrderSize = baseOrderSize * (1 + stopLossPercent / 100);
      if (stopLossOrderSize < minNotional) {
        failureDetails.push('Stop loss is less than minimum order amount.');
      }
      if (stopLossPercent >= 0) {
        failureDetails.push('Stop loss percent must be negative.');
      }
    }

    // Check overlap
    if (diff > overlapDifferenceTolerance) {
      if (overlapAchieved < overlap - overlapDifferenceTolerance) {
        failureDetails.push('Overlap is not reached.');
      } else {
        failureDetails.push('Overlap exceeds the desired tolerance.');
      }
    }

    const fullPositionSize = getFullPositionSize(baseOrderSize, orderSizeScale, maxOrders);
    const weightedPercent = getWeightedPriceChangePercent(baseOrderSize, maxOrders, priceChange, priceChangeScale, orderSizeScale);
    const denominator = 100 + weightedPercent;
    let weightedDrawdown = Number.POSITIVE_INFINITY;
    if (denominator !== 0) {
      weightedDrawdown = ((overlapAchieved - weightedPercent) / denominator) * 100;
    }

    if (!validateStopLoss(fullPositionSize, stopLossPercent, weightedDrawdown)) {
      failureDetails.push('Stop loss percent constraint is not met.');
    }

    const meets = failureDetails.length === 0;
    return {
      meets, diff, weightedDrawdown, failureDetails, 
    };
  };

  const globalPreSearch = () => {
    if (globalSearchSamples <= 0) return null;

    let bestScale = minSizeScale;
    let bestMaxOrders = initialMaxAdditionalOrders;
    let bestOverlapDiff = Number.MAX_VALUE;
    let bestFailureDetails: string[] | null = null;

    const minOrders = initialMaxAdditionalOrders > 0 ? 1 : 0;
    const maxOrdersStart = initialMaxAdditionalOrders > 0 ? initialMaxAdditionalOrders : 0;

    for (let attempt = 0; attempt < globalSearchSamples; attempt++) {
      const randomScale = parseFloat((minSizeScale + Math.random() * (maxSizeScale - minSizeScale)).toFixed(5));
      const randomMaxOrders = Math.floor(minOrders + Math.random() * (maxOrdersStart - minOrders + 1));

      const baseOrderSize = getBaseOrderSize(randomScale, randomMaxOrders);
      const resultCheck = scenarioMeetsConstraints(
        baseOrderSize,
        randomMaxOrders,
        initialAdditionalOrderPriceChange,
        initialAdditionalOrderPriceChangeScale,
        randomScale,
      );
      const {
        diff, meets, 
      } = resultCheck;

      if (diff < bestOverlapDiff) {
        bestOverlapDiff = diff;
        bestScale = randomScale;
        bestMaxOrders = randomMaxOrders;
        bestFailureDetails = meets ? [] : resultCheck.failureDetails;
      }

      if (meets && diff <= overlapDifferenceTolerance) {
        break;
      }
    }

    return {
      bestScale,
      bestMaxOrders,
      bestOverlapDiff,
      bestFailureDetails: bestFailureDetails || undefined,
    };
  };

  const globalBest = globalPreSearch();

  const minOrders = initialMaxAdditionalOrders > 0 ? 1 : 0;
  const maxOrdersStart = initialMaxAdditionalOrders > 0 ? initialMaxAdditionalOrders : 0;

  const tryAllCombinations = (
    currentAlpha: number,
    baseline?: { diff: number; weightedDrawdown: number; },
  ): any => {
    if (!baseline) {
      // Stage 1 or single-stage: minimal diff scenario
      let bestScenario: any = null;
      let bestDiff = Number.MAX_VALUE;
      for (let scale = minSizeScale; scale <= maxSizeScale; scale = parseFloat((scale + iterationStep).toFixed(5))) {
        for (let maxOrders = maxOrdersStart; maxOrders >= minOrders; maxOrders--) {
          const optimizationResult = optimizeScenario(scale, maxOrders, currentAlpha);
          if (optimizationResult && optimizationResult.diff < bestDiff) {
            bestDiff = optimizationResult.diff;
            bestScenario = optimizationResult;
          }
        }
      }
      return bestScenario;
    } else {
      // Stage 2: improve WeightedDrawdown without increasing diff
      const {
        diff: baselineDiff, weightedDrawdown: baselineWDD, 
      } = baseline;
      let bestImprovementWDD = baselineWDD;
      let chosenScenario: any = null;
      for (let scale = minSizeScale; scale <= maxSizeScale; scale = parseFloat((scale + iterationStep).toFixed(5))) {
        for (let maxOrders = maxOrdersStart; maxOrders >= minOrders; maxOrders--) {
          const optimizationResult = optimizeScenario(scale, maxOrders, currentAlpha);
          if (optimizationResult) {
            if (
              optimizationResult.diff <= baselineDiff &&
                optimizationResult.weightedDrawdownPercent > baselineWDD
            ) {
              if (optimizationResult.weightedDrawdownPercent > bestImprovementWDD) {
                bestImprovementWDD = optimizationResult.weightedDrawdownPercent;
                chosenScenario = optimizationResult;
              }
            }
          }
        }
      }
      return chosenScenario || null;
    }
  };

  const optimizeScenario = (
    scale: number,
    maxOrders: number,
    currentAlpha: number,
  ): (null | {
    calibratedAdditionalOrderSizeScale: number;
    calibratedAdditionalOrderPriceChange: number;
    calibratedAdditionalOrderPriceChangeScale: number;
    calibratedMaxAdditionalOrders: number;
    baseOrderSize: number;
    fullPositionSize: number;
    stopLossOrderSize: number | string;
    stopLossPercent: number;
    overlap: number;
    weightedPriceChangePercent: number;
    weightedDrawdownPercent: number;
    diff: number;
    iterations: number;
  }) => {
    const optimizationResult = optimizeWithNelderMead(scale, maxOrders, currentAlpha);
    if (!optimizationResult.success || !optimizationResult.result) return null;
    return optimizationResult.result;
  };

  const optimizeWithNelderMead = (
    orderSizeScale: number,
    maxOrders: number,
    currentAlpha: number,
  ): {
    result: any;
    success: boolean;
    failureDetails: string[];
    overlapDiff: number;
  } => {
    const baseOrderSize = getBaseOrderSize(orderSizeScale, maxOrders);

    const costFunction = (params: number[]): number => {
      const [trialPriceChange, trialPriceChangeScale] = params;
      if (
        trialPriceChange < minPriceChange ||
          trialPriceChange > maxPriceChange ||
          trialPriceChangeScale < minPriceChangeScale ||
          trialPriceChangeScale > maxPriceChangeScale
      ) {
        return Number.MAX_VALUE;
      }

      const overlapAchieved = computeOverlapAchieved(maxOrders, trialPriceChange, trialPriceChangeScale);
      const candidateWeightedPercent = getWeightedPriceChangePercent(
        baseOrderSize,
        maxOrders,
        trialPriceChange,
        trialPriceChangeScale,
        orderSizeScale,
      );
      const denominator = 100 + candidateWeightedPercent;
      let candidateWeightedDrawdown = Number.POSITIVE_INFINITY;
      if (denominator !== 0) {
        candidateWeightedDrawdown = ((overlapAchieved - candidateWeightedPercent) / denominator) * 100;
      }

      const overlapPenalty = Math.abs(overlap - overlapAchieved);
      const weightedPenalty = candidateWeightedDrawdown < 0 ? -candidateWeightedDrawdown * currentAlpha : 0;

      return overlapPenalty + weightedPenalty;
    };

    let optimizedPriceChange = initialAdditionalOrderPriceChange;
    let optimizedPriceChangeScale = initialAdditionalOrderPriceChangeScale;
    let iterations = 0;

    if (overlap === 0 && maxOrders === 0) {
      // No optimization run needed here
    } else if (maxOrders > 0) {
      const optimizationResult = nelderMead(
        costFunction,
        [initialAdditionalOrderPriceChange, initialAdditionalOrderPriceChangeScale],
        {
          maxIterations, minErrorDelta: 1e-6, minTolerance: 1e-5, rho: 1, chi: 2, psi: 0.5, sigma: 0.5, 
        },
      );
      [optimizedPriceChange, optimizedPriceChangeScale] = optimizationResult.x;
      iterations = optimizationResult.iterations;
    } else {
      // maxOrders=0, optimize only priceChange
      const singleCostFunction = (params: number[]): number => {
        const [trialPriceChange] = params;
        if (trialPriceChange < minPriceChange || trialPriceChange > maxPriceChange) {
          return Number.MAX_VALUE;
        }

        const currentOverlap = trialPriceChange;
        const candidateWeightedPercent = getWeightedPriceChangePercent(
          baseOrderSize,
          maxOrders,
          trialPriceChange,
          optimizedPriceChangeScale,
          orderSizeScale,
        );
        const denominator = 100 + candidateWeightedPercent;
        let candidateWeightedDrawdown = Number.POSITIVE_INFINITY;
        if (denominator !== 0) {
          candidateWeightedDrawdown = ((currentOverlap - candidateWeightedPercent) / denominator) * 100;
        }

        const overlapPenalty = Math.abs(overlap - currentOverlap);
        const weightedPenalty = candidateWeightedDrawdown < 0 ? -candidateWeightedDrawdown * currentAlpha : 0;

        return overlapPenalty + weightedPenalty;
      };

      const optimizationResult = nelderMead(
        singleCostFunction,
        [initialAdditionalOrderPriceChange],
        {
          maxIterations, minErrorDelta: 1e-6, minTolerance: 1e-5, rho: 1, chi: 2, psi: 0.5, sigma: 0.5, 
        },
      );
      [optimizedPriceChange] = optimizationResult.x;
      iterations = optimizationResult.iterations;
    }

    // Check final scenario constraints
    const scenarioCheck = scenarioMeetsConstraints(
      baseOrderSize,
      maxOrders,
      optimizedPriceChange,
      optimizedPriceChangeScale,
      orderSizeScale,
    );
    if (!scenarioCheck.meets) {
      return {
        result: null, success: false, failureDetails: scenarioCheck.failureDetails, overlapDiff: scenarioCheck.diff, 
      };
    }

    const fullPositionSize = getFullPositionSize(baseOrderSize, orderSizeScale, maxOrders);
    const weightedPercent = getWeightedPriceChangePercent(
      baseOrderSize,
      maxOrders,
      optimizedPriceChange,
      optimizedPriceChangeScale,
      orderSizeScale,
    );
    const stopLossOrderSize =
        stopLossPercent !== 0 ? parseFloat((fullPositionSize * (1 + stopLossPercent / 100)).toFixed(5)) : 'disabled';

    return {
      result: {
        calibratedAdditionalOrderSizeScale: parseFloat(orderSizeScale.toFixed(3)),
        calibratedAdditionalOrderPriceChange: parseFloat(optimizedPriceChange.toFixed(3)),
        calibratedAdditionalOrderPriceChangeScale: parseFloat(optimizedPriceChangeScale.toFixed(3)),
        calibratedMaxAdditionalOrders: maxOrders,
        baseOrderSize: parseFloat(baseOrderSize.toFixed(5)),
        fullPositionSize: parseFloat(fullPositionSize.toFixed(5)),
        stopLossOrderSize,
        stopLossPercent,
        overlap: parseFloat(computeOverlapAchieved(maxOrders, optimizedPriceChange, optimizedPriceChangeScale).toFixed(2)),
        weightedPriceChangePercent: parseFloat(weightedPercent.toFixed(2)),
        weightedDrawdownPercent: parseFloat(scenarioCheck.weightedDrawdown.toFixed(2)),
        diff: parseFloat(scenarioCheck.diff.toFixed(2)),
        iterations,
      },
      success: true,
      failureDetails: [],
      overlapDiff: scenarioCheck.diff,
    };
  };

  const iterateParameters = (): {
    message: string;
    result?: any;
    failureDetails?: string[];
    bestMatchingParameters?: any;
  } => {
    const initialAlpha = alpha;
    const alphaForStage1 = alpha > 0 ? 0 : alpha;

    // Stage 1: alpha=0 or alphaForStage1
    const stage1Result = tryAllCombinations(alphaForStage1);
    if (!stage1Result) {
      const failureDetails: string[] = [];

      if (globalBest) {
        // Append specific failure reasons from global search
        if (globalBest.bestFailureDetails && globalBest.bestFailureDetails.length > 0) {
          failureDetails.push(...globalBest.bestFailureDetails);
        } else {
          failureDetails.push('No viable parameter configuration found.');
        }

        // Construct best matching parameters
        const bestBaseOrderSize = getBaseOrderSize(globalBest.bestScale, globalBest.bestMaxOrders);
        const overlapAchieved = computeOverlapAchieved(globalBest.bestMaxOrders, initialAdditionalOrderPriceChange, initialAdditionalOrderPriceChangeScale);
        const weightedPercent = getWeightedPriceChangePercent(
          bestBaseOrderSize,
          globalBest.bestMaxOrders,
          initialAdditionalOrderPriceChange,
          initialAdditionalOrderPriceChangeScale,
          globalBest.bestScale,
        );
        const denominator = 100 + weightedPercent;
        let weightedDrawdown = Number.POSITIVE_INFINITY;
        if (denominator !== 0) {
          weightedDrawdown = ((overlapAchieved - weightedPercent) / denominator) * 100;
        }
        const stopLossOrderSize =
            stopLossPercent !== 0
              ? parseFloat((getFullPositionSize(bestBaseOrderSize, globalBest.bestScale, globalBest.bestMaxOrders) * (1 + stopLossPercent / 100)).toFixed(5))
              : 'disabled';

        const bestMatchingParameters = {
          calibratedAdditionalOrderSizeScale: parseFloat(globalBest.bestScale.toFixed(3)),
          calibratedAdditionalOrderPriceChange: parseFloat(initialAdditionalOrderPriceChange.toFixed(3)),
          calibratedAdditionalOrderPriceChangeScale: parseFloat(initialAdditionalOrderPriceChangeScale.toFixed(3)),
          calibratedMaxAdditionalOrders: globalBest.bestMaxOrders,
          baseOrderSize: parseFloat(bestBaseOrderSize.toFixed(5)),
          fullPositionSize: parseFloat(getFullPositionSize(bestBaseOrderSize, globalBest.bestScale, globalBest.bestMaxOrders).toFixed(5)),
          stopLossOrderSize,
          stopLossPercent,
          overlap: parseFloat(overlapAchieved.toFixed(2)),
          weightedPriceChangePercent: parseFloat(weightedPercent.toFixed(2)),
          weightedDrawdownPercent: parseFloat(weightedDrawdown.toFixed(2)),
          diff: parseFloat(globalBest.bestOverlapDiff.toFixed(2)),
          iterations: 0, // Iterations not tracked in global pre-search
        };

        return {
          message: 'Calibration failed',
          failureDetails,
          bestMatchingParameters,
        };
      }

      // If no global best is found
      failureDetails.push('Unable to find any viable parameter configuration.');

      return {
        message: 'Calibration failed', failureDetails, 
      };
    }

    if (alpha <= 0) {
      // No stage 2
      return {
        message: 'Parameters successfully calibrated', result: stage1Result, 
      };
    }

    // Stage 2: Try to improve WeightedDrawdown without increasing diff
    const baseline = {
      diff: stage1Result.diff, weightedDrawdown: stage1Result.weightedDrawdownPercent, 
    };
    const stage2Result = tryAllCombinations(initialAlpha, baseline);

    if (!stage2Result) {
      // Stage 2 failed to improve, but calibration is still successful with Stage 1 parameters
      return {
        message: 'Parameters successfully calibrated (Stage 1 only)',
        result: stage1Result,
      };
    }

    // Stage 2 succeeded
    return {
      message: 'Parameters successfully calibrated (multi-stage)', result: stage2Result, 
    };
  };

  return iterateParameters();
}
