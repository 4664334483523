import { Box, Stack } from '@mui/material';
import { MainButton, Text } from 'shared/ui';
import {
  background,
  button,
  inner,
  left,
  right,
  title,
} from '../styles';

export const Banner = () => {
  const linkHandler = (link: string) => {
    return () => {
      window.open(link, '_blank');
    };
  };

  return (
    <Box sx={background}>
      <Box sx={left}>
        <Box sx={title}>
          <Text>
            Step Up Your Game for a $20,000 Prize Pool!
          </Text>
        </Box>

        <Stack sx={inner}>
          <Text type='secondary'>
            Link your Bybit account to Skyrexio, start trading, and watch your share of the reward grow as you increase your activity.
          </Text>
        </Stack>

        <Stack
          direction='row'
          gap={1}
          alignItems='center'
        >
          <MainButton
            type='primary'
            size='small'
            styles={button}
            onClick={linkHandler('https://www.bybit.com/bybitinstitutional/broker-service/brokerRegister?brokerId=BRK000388')}
          >
            Join in a click
          </MainButton>

          <MainButton
            type='primary'
            size='small'
            styles={button}
            onClick={linkHandler('https://www.bybit.com/en/trading-contest/game/?name=skyrexq4&period_id=2377')}
            ghost={true}
          >
            Leaderboard
          </MainButton>
        </Stack>
      </Box>

      <Box sx={right}>
        <img src='/images/banner-bg.jpg' alt='banner-bg' />
      </Box>
    </Box> 
  );
};
