export const ADA = (
  <svg width='120' height='120' viewBox='0 0 120 120' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='60' cy='60' r='60' fill='#EDF0F4'/>
    <path d='M41.9309 59.6953C41.7602 62.6605 44.0428 65.199 47.008 65.3697C47.1146 65.3697 47.2213 65.3697 47.3279 65.3697C50.3145 65.3697 52.725 62.9591 52.7037 59.9726C52.7037 56.986 50.2931 54.5755 47.3066 54.5968C44.4481 54.5968 42.0802 56.8367 41.9309 59.6953Z' fill='#0033AD'/>
    <path d='M21.8364 58.3085C20.8764 58.2445 20.0445 58.9912 20.0018 59.9511C19.9591 60.9111 20.6844 61.743 21.6444 61.7857C22.6043 61.8497 23.415 61.1031 23.479 60.1431C23.543 59.1831 22.7963 58.3725 21.8364 58.3085Z' fill='#0033AD'/>
    <path d='M41.5908 28.4213C42.4441 27.9946 42.7854 26.928 42.3588 26.0747C41.9321 25.2214 40.8655 24.8801 40.0122 25.3067C39.1589 25.7334 38.8176 26.7787 39.2442 27.632C39.6709 28.5066 40.7162 28.8692 41.5908 28.4213C41.5908 28.4213 41.5695 28.4213 41.5908 28.4213Z' fill='#0033AD'/>
    <path d='M47.0511 37.9567C48.3737 37.2954 48.907 35.6741 48.2457 34.3515C47.5844 33.0289 45.9632 32.4956 44.6405 33.1569C43.3179 33.8182 42.7846 35.4394 43.4459 36.762C44.1072 38.0846 45.7285 38.618 47.0511 37.9567Z' fill='#0033AD'/>
    <path d='M28.662 44.5057C29.686 45.167 31.0512 44.8897 31.7339 43.8657C32.3952 42.8418 32.1179 41.4765 31.0939 40.7939C30.0699 40.1326 28.7047 40.4099 28.022 41.4338C27.3394 42.4578 27.6381 43.8444 28.662 44.5057Z' fill='#0033AD'/>
    <path d='M31.9257 57.3483C30.4325 57.2629 29.1738 58.3936 29.0885 59.8868C29.0032 61.3801 30.1338 62.6387 31.6271 62.724C33.1203 62.8094 34.3789 61.6788 34.4643 60.1855C34.5496 58.7136 33.419 57.4336 31.9257 57.3483Z' fill='#0033AD'/>
    <path d='M28.9619 75.4171C27.874 75.9718 27.426 77.2944 27.9806 78.4037C28.5353 79.4916 29.8579 79.9396 30.9672 79.385C32.0551 78.8303 32.5031 77.5077 31.9484 76.3984C31.3938 75.3105 30.0499 74.8625 28.9619 75.4171Z' fill='#0033AD'/>
    <path d='M39.4139 51.7811C40.8858 52.7411 42.8484 52.3358 43.787 50.8639C44.747 49.3919 44.3417 47.4293 42.8697 46.4907C41.3978 45.5308 39.4352 45.9361 38.4966 47.408C37.5366 48.8586 37.9419 50.8212 39.4139 51.7811Z' fill='#0033AD'/>
    <path d='M72.5858 37.7436C73.8231 38.5542 75.5084 38.2129 76.319 36.9756C77.1296 35.7383 76.7883 34.0531 75.551 33.2424C74.3137 32.4318 72.6285 32.7731 71.8179 34.0104C70.9859 35.269 71.3272 36.9329 72.5858 37.7436Z' fill='#0033AD'/>
    <path d='M78.0902 28.251C78.9009 28.7843 79.9675 28.5496 80.5008 27.739C81.0341 26.9284 80.7994 25.8617 79.9888 25.3284C79.1782 24.7951 78.1116 25.0298 77.5782 25.8191C77.0663 26.6297 77.3009 27.7177 78.0902 28.251Z' fill='#0033AD'/>
    <path d='M72.992 54.5539C70.0268 54.3832 67.4669 56.6658 67.3176 59.631C67.1469 62.5962 69.4295 65.1561 72.3947 65.3054C72.5013 65.3054 72.608 65.3054 72.6933 65.3054C75.6585 65.3054 78.0691 62.8949 78.0691 59.9083C78.0904 57.0711 75.8505 54.7032 72.992 54.5539Z' fill='#0033AD'/>
    <path d='M48.8211 51.3964C49.7384 53.2096 51.5943 54.3616 53.6422 54.3616C56.6074 54.3616 59.018 51.951 59.018 48.9645C59.018 48.1325 58.826 47.3006 58.442 46.5326C57.0981 43.8661 53.8556 42.7994 51.2103 44.1434C48.5438 45.5087 47.4772 48.7512 48.8211 51.3964Z' fill='#0033AD'/>
    <path d='M91.0377 44.5058C92.1257 43.9511 92.5523 42.6072 91.9977 41.5193C91.443 40.4313 90.0991 40.0047 89.0111 40.5593C87.9232 41.1139 87.4965 42.4365 88.0298 43.5245C88.6058 44.6125 89.9284 45.0604 91.0377 44.5058Z' fill='#0033AD'/>
    <path d='M77.3852 46.212C75.8279 47.0013 75.188 48.8999 75.9772 50.4572C76.7665 52.0144 78.6651 52.6544 80.2224 51.8651C81.7797 51.0758 82.4196 49.1772 81.6303 47.62C80.841 46.0627 78.9425 45.4441 77.3852 46.212Z' fill='#0033AD'/>
    <path d='M59.787 27.4403C61.003 27.5043 62.0483 26.5657 62.1336 25.3497C62.2189 24.1338 61.259 23.0885 60.043 23.0032C58.8271 22.9392 57.7818 23.8564 57.6964 25.0724C57.6324 26.3097 58.5711 27.355 59.787 27.4403Z' fill='#0033AD'/>
    <path d='M59.7658 41.3711C61.5151 41.4778 63.0084 40.1338 63.0937 38.3846C63.2003 36.6353 61.8564 35.142 60.1071 35.0567C58.3579 34.9501 56.8646 36.294 56.7793 38.0432C56.694 39.7925 58.0166 41.2858 59.7658 41.3711Z' fill='#0033AD'/>
    <path d='M42.615 73.7104C44.1722 72.9211 44.8122 71.0225 44.0229 69.4653C43.2336 67.908 41.335 67.268 39.7778 68.0573C38.2205 68.8466 37.5805 70.7452 38.3698 72.3025C39.1591 73.8597 41.0577 74.4997 42.615 73.7104Z' fill='#0033AD'/>
    <path d='M61.8139 46.0003C60.1926 48.4961 60.8753 51.824 63.3711 53.4453C65.867 55.0665 69.1949 54.3839 70.8161 51.888C72.4374 49.3921 71.7548 46.0642 69.2589 44.443C68.3843 43.867 67.3603 43.5684 66.315 43.5684C64.5018 43.5684 62.8165 44.4857 61.8139 46.0003Z' fill='#0033AD'/>
    <path d='M71.1783 68.5267C69.8344 65.8602 66.5919 64.7936 63.9467 66.1375C61.2801 67.4815 60.2135 70.724 61.5574 73.3692C62.9014 76.0357 66.1439 77.1024 68.7891 75.7584C71.4343 74.4358 72.5223 71.236 71.1997 68.5694C71.1997 68.5481 71.1997 68.5481 71.1783 68.5267Z' fill='#0033AD'/>
    <path d='M80.5865 68.1421C79.1146 67.1821 77.152 67.5874 76.2134 69.0594C75.2534 70.5313 75.6587 72.4939 77.1307 73.4325C78.6026 74.3925 80.5652 73.9872 81.5038 72.5152C82.4638 71.0646 82.0584 69.102 80.5865 68.1421Z' fill='#0033AD'/>
    <path d='M90.9115 60.0576C90.9969 58.5643 89.8663 57.3057 88.373 57.2203C86.8797 57.135 85.6211 58.2656 85.5358 59.7589C85.4504 61.2522 86.5811 62.5108 88.0743 62.5961C89.5463 62.6814 90.8262 61.5295 90.9115 60.0576Z' fill='#0033AD'/>
    <path d='M98.3559 58.1376C97.3959 58.0736 96.564 58.8203 96.5213 59.7802C96.4787 60.7402 97.204 61.5721 98.1639 61.6148C99.1239 61.6788 99.9345 60.9322 99.9985 59.9722C100.041 59.0122 99.3159 58.2016 98.3559 58.1376Z' fill='#0033AD'/>
    <path d='M91.3364 75.4169C90.3125 74.7556 88.9472 75.0329 88.2646 76.0569C87.6033 77.0808 87.8806 78.4461 88.9045 79.1287C89.9285 79.7901 91.2938 79.5127 91.9764 78.4888C92.659 77.4648 92.3604 76.0782 91.3364 75.4169Z' fill='#0033AD'/>
    <path d='M41.8894 91.6722C41.0787 91.1389 40.0121 91.3735 39.4788 92.1628C38.9455 92.9735 39.1802 94.0401 39.9695 94.5734C40.7801 95.1067 41.8467 94.872 42.38 94.0827C42.9347 93.2934 42.7 92.2055 41.8894 91.6722Z' fill='#0033AD'/>
    <path d='M78.4106 91.5011C77.5573 91.9278 77.216 92.9944 77.6427 93.8477C78.0693 94.701 79.1359 95.0423 79.9892 94.6156C80.8425 94.189 81.1838 93.1437 80.7572 92.2904C80.3305 91.4158 79.2853 91.0531 78.4106 91.5011C78.432 91.5011 78.4106 91.5011 78.4106 91.5011Z' fill='#0033AD'/>
    <path d='M58.1867 73.9238C59.808 71.4279 59.1254 68.1 56.6295 66.4574C54.1336 64.8362 50.8057 65.5188 49.1631 68.0147C47.5205 70.5106 48.2245 73.8384 50.7204 75.481C51.595 76.057 52.619 76.3557 53.6643 76.3557C55.4989 76.377 57.1841 75.4597 58.1867 73.9238Z' fill='#0033AD'/>
    <path d='M47.4131 82.179C46.1758 81.3683 44.4906 81.7096 43.6799 82.9469C42.8693 84.1842 43.2106 85.8695 44.4479 86.6801C45.6852 87.4907 47.3704 87.1494 48.1811 85.9121C49.013 84.6748 48.6717 82.9896 47.4131 82.179Z' fill='#0033AD'/>
    <path d='M59.9788 92.4826C58.7628 92.4186 57.7176 93.3572 57.6322 94.5732C57.5469 95.7891 58.5069 96.8344 59.7228 96.9197C60.9388 96.9837 61.984 96.0664 62.0694 94.8505C62.1334 93.6132 61.1947 92.5679 59.9788 92.4826Z' fill='#0033AD'/>
    <path d='M59.9998 78.5528C58.2505 78.4461 56.7573 79.7901 56.672 81.5393C56.5653 83.2886 57.9092 84.7819 59.6585 84.8672C61.4077 84.9525 62.901 83.6299 62.9863 81.8807C63.093 80.1527 61.7491 78.6381 59.9998 78.5528Z' fill='#0033AD'/>
    <path d='M72.9494 81.966C71.6268 82.6487 71.0935 84.2699 71.7762 85.5925C72.4588 86.9152 74.08 87.4485 75.4027 86.7658C76.7253 86.1045 77.2586 84.4833 76.5973 83.1607C75.9146 81.838 74.2934 81.2834 72.9494 81.966Z' fill='#0033AD'/>
  </svg>
);
