import { SxProps, Theme } from '@mui/material';

export const SETTING: SxProps<Theme> = {
  gap: '2px',
  '&>h1': {
    fontSize: 6,
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: '0.18px',
  },
  '&>span': {
    fontSize: 11,
    fontWeight: 600,
    lineHeight: 'normal',
    letterSpacing: '0.34px',
  },
};
