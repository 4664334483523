import { IRiskManagmentAdditional } from 'entities/risk-managment-additional/interfaces';
import { BotData } from 'pages/trading-bots/my-bot/ui/my-bot';

export const getAdditionalEntryOrdersValue = (bot: BotData) => {
  const value: IRiskManagmentAdditional = {
    isOpened: !!bot.maxSafetyOrders,
    segment: {
      value: bot.alertAdditionalBaseOrderType ? 'alert' : 'preset',
      preset: {
        orders: bot.maxSafetyOrders || 1,
        priceChange: bot.priceDeviationPercentSafety || -3,
        ordersSizeScale: bot.martingaleVolumeCoefficient || 1,
        ordersPriceChangeScale: bot.martingalePriceStepCoefficient || 1,
        orderType: (bot.additionalBaseOrderType?.split('_')?.[1] || bot.additionalBaseOrderType || 'market').toLowerCase() as IRiskManagmentAdditional['segment']['preset']['orderType'],
      },
      alert: {
        orders: bot.maxSafetyOrders || 1,
        ordersSizeScale: bot.martingaleVolumeCoefficient || 1,
        orderType: (bot.alertAdditionalBaseOrderType || 'market').toLowerCase() as IRiskManagmentAdditional['segment']['alert']['orderType'],
      },
    },
  };

  return value;
};
