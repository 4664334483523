import { Copy, Label } from 'widgets';
import { secretToken, sourceUuid } from 'shared/consts';
import { generateCustomAlertSendRequest, generateIndicatorAlertMessage, generateStrategyEntryFunction } from 'shared/helpers';
import { Text } from 'shared/ui';
import { TradingViewLink } from '../../../../../components';
import { IAlertProps } from '../interfaces';

export const Alerts = (props: IAlertProps) => {
  const getContent = () => {
    switch (props.alertSource) {
    case 'indicator':
      return (
        <Copy
          label={(
            <Label
              title='Alert message'
              desciption={(
                <Text>
                  Copy and paste this text into alert message on {' '}

                  <TradingViewLink />
                </Text>
              )}
            />
          )}
          value={generateIndicatorAlertMessage(sourceUuid, secretToken(props.exchangeAccountUuid), 'close')}
          display='row'
        />
      );
    case 'strategy':
      return (
        <Copy
          label={(
            <Label
              title='PineScript parameter'
              desciption={(
                <Text>
                  Add the parameter to your strategy exit function on {' '}

                  <TradingViewLink />
                </Text>
              )}
            />
          )}
          value={generateStrategyEntryFunction(sourceUuid, secretToken(props.exchangeAccountUuid), 'close')}
          display='row'
        />
      );
    case 'custom':
      return (
        <Copy
          label={(
            <Label
              title='JSON body to execute base entry'
              desciption='Configure a POST request with JSON body to execute base entry'
            />
          )}
          value={generateCustomAlertSendRequest(sourceUuid, secretToken(props.exchangeAccountUuid), 'close')}
          display='row'
        />
      );
    default:
      return null;
    }
  };

  return getContent();
};
