import { SxProps, Theme } from '@mui/material';

export const headerItem: SxProps<Theme> = {
  flex: 1,
  '&>span': {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '30px',
  },
  '&:not(:last-of-type)': {
    maxWidth: 84,
  },
};
