export const generateCustomAlertSendRequest = (sourceUuid?: string, secretToken?: string, position?: number | string) => {
  return {
    'base': 'Symbol to trade',
    'quote': 'Market of the bot',
    'position': typeof position === 'string' ? position : `entry${position}`,
    'price': 'Price to execute order',
    'sourceUuid': sourceUuid,
    'secretToken': secretToken,
    'timestamp': 'Current time in UNIX format',
  };
};
